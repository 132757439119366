import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import { TripPassengersTabComponent } from 'vfc-admin/components/pages/trip-passengers-tab';
import { awaitPromise } from 'vfc-admin/helpers/await-promise';
import type { Trip } from 'vfc-admin/types/trip';
export default RouteTemplate<{
    Args: {
        model: {
            tripPromise: Promise<Trip>;
        };
    };
}>(template(`
    {{#let (awaitPromise @model.tripPromise) as |tripPromise|}}
      {{#if tripPromise.isPending}}
        Loading
      {{else if tripPromise.isSuccess}}
        <TripPassengersTabComponent @trip={{tripPromise.result}} />
      {{/if}}
    {{/let}}
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
