import { end } from '@ember/runloop';
import Service from '@ember/service';
import { service } from '@ember/service';
import config from 'vfc-admin/config/environment';

import type AuthenticatedHttpClient from 'vfc-admin/services/auth/http-client';
import type { Extra, Transport } from 'vfc-admin/types/trip';

export default class TripTransportService extends Service {
  @service('auth/http-client') declare authenticatedHttpClient: AuthenticatedHttpClient;

  static ENDPOINTS = {
    create: '/trip/:id/transport',
    update: '/trip/:id/transport',
    newExtra: '/trip/:id/transport/extra',
    updateExtra: '/trip/:id/transport/extra/:extraId',
    removeExtra: '/trip/:id/transport/extra/:extraId',
  };

  create(tripId: string, body: any): Promise<Transport> {
    const endpoint = TripTransportService.ENDPOINTS.update.replace(':id', tripId);

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    return this.authenticatedHttpClient.POST<Transport>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}`,
      headers,
      body,
    });
  }

  update(tripId: string, body: any): Promise<Transport> {
    const endpoint = TripTransportService.ENDPOINTS.update.replace(':id', tripId);

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    return this.authenticatedHttpClient.PUT<Transport>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}`,
      headers,
      body,
    });
  }

  newExtra(tripId: string, extra: Extra): Promise<Extra> {
    const endpoint = TripTransportService.ENDPOINTS.newExtra.replace(':id', tripId);
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    return this.authenticatedHttpClient.POST<any>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}`,
      headers,
      body: extra,
    });
  }

  updateExtra(tripId: string, extra: Extra): Promise<any> {
    const endpoint = TripTransportService.ENDPOINTS.updateExtra
      .replace(':id', tripId)
      .replace(':extraId', extra.id);

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    return this.authenticatedHttpClient.PUT<any>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}`,
      headers,
      body: extra,
    });
  }

  removeExtra(tripId: string, extraId: string) {
    const endpoint = TripTransportService.ENDPOINTS.removeExtra
      .replace(':id', tripId)
      .replace(':extraId', extraId);

    return this.authenticatedHttpClient.DELETE<any>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}`,
    });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'trip-transport-service': TripTransportService;
  }
}
