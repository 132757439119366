import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { isBlank, isPresent } from '@ember/utils';
import { awaitPromise } from 'vfc-admin/helpers/await-promise';
import { isActiveRoute } from 'vfc-admin/helpers/is-active-route';
import { downloadFile } from 'vfc-admin/utils/file-utils';
import { concat } from 'vfc-admin/utils/string-utils';
import type { IntlService } from 'ember-intl';
import type TripDocumentsService from 'vfc-admin/services/trip-documents-service';
import type TripService from 'vfc-admin/services/trip-service';
import type { Trip } from 'vfc-admin/types/trip';
interface TripTabsSignature {
    Element: HTMLDivElement;
    Args: {
        tripType: 'current-course' | 'pending' | 'incomplete';
        tripPromise: Promise<Trip>;
        tripId: string;
    };
}
let TripTabs = class TripTabs extends Component<TripTabsSignature> {
    @service
    tripService: TripService;
    @service
    tripDocumentsService: TripDocumentsService;
    @service
    intl: IntlService;
    get tripResource() {
        return awaitPromise<Trip>(this.args.tripPromise);
    }
    get hasSignedContract(): boolean {
        return (isPresent(this.tripResource?.result) && Boolean(this.tripResource.result.contractState === 'accepted'));
    }
    @action
    downloadContract() {
        this.tripDocumentsService.downloadContractPDF(this.args.tripId).then(async (response1: Response)=>{
            const filename1 = this.intl.t('page.trips.current-course.trip.files.contract-filename', {
                reference: this.tripResource?.result?.reference as string
            });
            downloadFile(response1);
        });
    }
    @action
    downloadProforma() {
        this.tripDocumentsService.downloadProformaPDF(this.args.tripId).then(async (response1: Response)=>{
            const filename1 = this.intl.t('page.trips.current-course.trip.files.proforma-filename', {
                reference: this.tripResource?.result?.reference as string
            });
            downloadFile(response1);
        });
    }
    static{
        template(`
    <div class="card">
      <div class="border-b border-light-dark py-4 px-6 flex items-center justify-between">
        <h5 class="card-title mb-0 flex items-center">
          Viaje:
          {{#if this.tripResource.isPending}}
            <span role="status" class="max-w-sm animate-pulse flex items-center">
              <span class="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-48"></span>
            </span>
          {{else if this.tripResource.isSuccess}}
            {{this.tripResource.result.destination.name}}
          {{/if}}
        </h5>
        <div class="flex gap-2">
          <button
            {{on "click" this.downloadProforma}}
            disabled={{isBlank this.tripResource.result.proforma}}
            type="button"
            class="btn-md inline-flex items-center gap-x-2 text-sm font-semibold rounded-md border border-transparent bg-lightprimary text-primaryemphasis enabled:hover:bg-primary enabled:hover:text-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 disabled:opacity-50 disabled:bg-lightprimary"
          >
            <i class="ti ti-file-text text-base"></i>
            Proforma
          </button>

          <button
            {{on "click" this.downloadContract}}
            disabled={{isBlank this.tripResource.result.contract}}
            type="button"
            class="btn-md text-sm font-semibold rounded-md border border-transparent enabled:hover:text-white disabled:opacity-50
              {{if
                this.hasSignedContract
                'bg-lightsuccess enabled:hover:text-darksuccess text-successemphasis enabled:hover:bg-success dark:hover:bg-darksuccess dark:text-success'
                'bg-lightwarning text-warningemphasis enabled:hover:bg-warning dark:enabled:hover:bg-darkwarning dark:text-warning'
              }}"
          >
            <i class="ti ti-file-text text-base"></i>
            Contrato
          </button>
        </div>
      </div>
      <div class="card-body">
        <div class="border-b border-gray-200 dark:border-gray-700">
          <nav class="flex space-x-2" aria-label="Tabs" role="tablist">
            <a
              class="hs-tab-active:font-semibold hs-tab-active:border-primary hs-tab-active:text-primary py-3 px-1 inline-flex items-center gap-x-2 border-b-2 border-transparent text-base whitespace-nowrap text-gray-500 hover:text-primary focus:outline-none focus:text-primary disabled:opacity-50 disabled:pointer-events-none dark:text-gray-400 dark:hover:text-primary
                {{if
                  (isActiveRoute (concat 'authenticated.trips.' @tripType '.trip.index'))
                  'active'
                }}"
              id="{{@tripType}}-trip-edit"
              href="/trips/{{@tripType}}/{{@tripId}}"
              data-hs-tab="#{{@tripType}}-trip-edit"
              aria-controls="{{@tripType}}-trip-edit"
              role="tab"
            >
              <i class="ti ti-file-pencil text-xl leading-tight font-medium"></i>
              Detalles
            </a>
            <a
              class="hs-tab-active:font-semibold hs-tab-active:border-primary hs-tab-active:text-primary py-3 px-1 inline-flex items-center gap-x-2 border-b-2 border-transparent text-base whitespace-nowrap text-gray-500 hover:text-primary focus:outline-none focus:text-primary disabled:opacity-50 disabled:pointer-events-none dark:text-gray-400 dark:hover:text-primary
                {{if
                  (isActiveRoute (concat 'authenticated.trips.' @tripType '.trip.accommodation'))
                  'active'
                }}"
              id="{{@tripType}}-trip-accommodation"
              href="/trips/{{@tripType}}/{{@tripId}}/accommodation"
              data-hs-tab="#{{@tripType}}-trip-accommodation"
              aria-controls="{{@tripType}}-trip-accommodation"
              role="tab"
            >
              <i class="ti ti-home text-xl leading-tight font-medium"></i>
              Alojamiento
            </a>
            <a
              class="hs-tab-active:font-semibold hs-tab-active:border-primary hs-tab-active:text-primary py-3 px-1 inline-flex items-center gap-x-2 border-b-2 border-transparent text-base whitespace-nowrap text-gray-500 hover:text-primary focus:outline-none focus:text-primary disabled:opacity-50 disabled:pointer-events-none dark:text-gray-400 dark:hover:text-primary
                {{if
                  (isActiveRoute (concat 'authenticated.trips.' @tripType '.trip.transport'))
                  'active'
                }}"
              id="{{@tripType}}-trip-transport"
              href="/trips/{{@tripType}}/{{@tripId}}/transport"
              data-hs-tab="#{{@tripType}}-trip-transport"
              aria-controls="{{@tripType}}-trip-transport"
              role="tab"
            >
              <i class="ti ti-plane text-xl leading-tight font-medium"></i>
              Transporte
            </a>
            <a
              class="hs-tab-active:font-semibold hs-tab-active:border-primary hs-tab-active:text-primary py-3 px-1 inline-flex items-center gap-x-2 border-b-2 border-transparent text-base whitespace-nowrap text-gray-500 hover:text-primary focus:outline-none focus:text-primary disabled:opacity-50 disabled:pointer-events-none dark:text-gray-400 dark:hover:text-primary
                {{if
                  (isActiveRoute (concat 'authenticated.trips.' @tripType '.trip.payments'))
                  'active'
                }}"
              id="{{@tripType}}-trip-payments"
              href="/trips/{{@tripType}}/{{@tripId}}/payments"
              data-hs-tab="#{{@tripType}}-trip-payments"
              aria-controls="{{@tripType}}-trip-payments"
              role="tab"
            >
              <i class="ti ti-credit-card text-xl leading-tight font-medium"></i>
              Pagos
            </a>
            <a
              class="hs-tab-active:font-semibold hs-tab-active:border-primary hs-tab-active:text-primary py-3 px-1 inline-flex items-center gap-x-2 border-b-2 border-transparent text-base whitespace-nowrap text-gray-500 hover:text-primary focus:outline-none focus:text-primary disabled:opacity-50 disabled:pointer-events-none dark:text-gray-400 dark:hover:text-primary
                {{if
                  (isActiveRoute (concat 'authenticated.trips.' @tripType '.trip.invoices'))
                  'active'
                }}"
              id="{{@tripType}}-trip-invoices"
              data-hs-tab="#{{@tripType}}-trip-invoices"
              aria-controls="{{@tripType}}-trip-invoices"
              href="/trips/{{@tripType}}/{{@tripId}}/invoices"
              role="tab"
            >
              <i class="ti ti-file-invoice text-xl leading-tight font-medium"></i>
              Facturas
            </a>
            <a
              class="hs-tab-active:font-semibold hs-tab-active:border-primary hs-tab-active:text-primary py-3 px-1 inline-flex items-center gap-x-2 border-b-2 border-transparent text-base whitespace-nowrap text-gray-500 hover:text-primary focus:outline-none focus:text-primary disabled:opacity-50 disabled:pointer-events-none dark:text-gray-400 dark:hover:text-primary
                {{if
                  (isActiveRoute (concat 'authenticated.trips.' @tripType '.trip.passengers'))
                  'active'
                }}"
              id="{{@tripType}}-trip-passengers"
              href="/trips/{{@tripType}}/{{@tripId}}/passengers"
              data-hs-tab="#{{@tripType}}-trip-passengers"
              aria-controls="{{@tripType}}-trip-passengers"
              role="tab"
            >
              <i class="ti ti-users text-xl leading-tight font-medium"></i>
              Pasajeros
            </a>
            <a
              class="hs-tab-active:font-semibold hs-tab-active:border-primary hs-tab-active:text-primary py-3 px-1 inline-flex items-center gap-x-2 border-b-2 border-transparent text-base whitespace-nowrap text-gray-500 hover:text-primary focus:outline-none focus:text-primary disabled:opacity-50 disabled:pointer-events-none dark:text-gray-400 dark:hover:text-primary
                {{if
                  (isActiveRoute (concat 'authenticated.trips.' @tripType '.trip.rooms'))
                  'active'
                }}"
              id="{{@tripType}}-trip-rooms"
              href="/trips/{{@tripType}}/{{@tripId}}/rooms"
              data-hs-tab="#{{@tripType}}-trip-rooms"
              aria-controls="{{@tripType}}-trip-rooms"
              role="tab"
            >
              <i class="ti ti-bed text-xl leading-tight font-medium"></i>
              Habitaciones
            </a>
            <a
              class="hs-tab-active:font-semibold hs-tab-active:border-primary hs-tab-active:text-primary py-3 px-1 inline-flex items-center gap-x-2 border-b-2 border-transparent text-base whitespace-nowrap text-gray-500 hover:text-primary focus:outline-none focus:text-primary disabled:opacity-50 disabled:pointer-events-none dark:text-gray-400 dark:hover:text-primary
                {{if
                  (isActiveRoute (concat 'authenticated.trips.' @tripType '.trip.documents'))
                  'active'
                }}"
              id="{{@tripType}}-trip-documents"
              href="/trips/{{@tripType}}/{{@tripId}}/documents"
              data-hs-tab="#{{@tripType}}-trip-documents"
              aria-controls="{{@tripType}}-trip-documents"
              role="tab"
            >
              <i class="ti ti-folder text-xl leading-tight font-medium"></i>
              Documentos
            </a>
          </nav>
        </div>
        <div class="mt-2">
          {{outlet}}
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export { TripTabs };
