import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { on } from '@ember/modifier';
import { action, get } from '@ember/object';
import { service } from '@ember/service';
import { type FormData, HeadlessForm } from 'ember-headless-form';
import { t } from 'ember-intl';
import { HtmlEditor } from 'vfc-admin/components/ui/html-editor';
import { noop } from 'vfc-admin/utils/fn-utils';
import { validateInputNatively } from 'vfc-admin/utils/form-utils';
import { concat } from 'vfc-admin/utils/string-utils';
import ButtonText from 'vfc-admin/components/ui/button/text';
import { extractTextFromHtml } from 'vfc-admin/utils/string-utils';
import type RouterService from '@ember/routing/router-service';
import type { TripTransportForm } from 'vfc-admin/types/trip';
import type { IntlService } from 'ember-intl';
export interface TripTransportFormSignature {
    Element: HTMLElement;
    Args: {
        form?: TripTransportForm;
        onResetForm?: (event: Event) => void;
        onSubmit: (data: FormData<TripTransportForm>) => Promise<void>;
    };
}
export default class TripTransportFormComponent extends Component<TripTransportFormSignature> {
    @service
    router: RouterService;
    @service
    intl: IntlService;
    validateInputNatively = (event1: Event)=>validateInputNatively.call(this, event1);
    @tracked
    errors = [];
    @tracked
    loading: boolean = false;
    @action
    onSubmit(data1: any) {
        this.loading = true;
        this.args.onSubmit(data1).catch((error1)=>{
            if (error1 instanceof Response) {
                error1.json().then((data1)=>{
                    this.errors = data1.errors;
                });
            }
        }).finally(()=>{
            this.loading = false;
        });
    }
    @action
    validateHtmlEditor(value1: string) {
        const text1 = extractTextFromHtml(value1);
        if (!text1) {
            return [
                {
                    type: 'valueMissing',
                    message: this.intl.t('common.form.validation.valueMissing')
                }
            ];
        }
    }
    static{
        template(`
    <HeadlessForm
      @data={{@form}}
      @validateOn="focusout"
      @revalidateOn="input"
      @onSubmit={{this.onSubmit}}
      {{on "reset" (if @onResetForm @onResetForm noop)}}
      as |form|
    >
      <div class="grid grid-cols-12 md:gap-6 gap-y-3">
        <div class="md:col-span-4 col-span-12 m-2">
          <form.Field @name="name" as |field|>
            <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
              Nombre
              <span class="text-red-500">*</span>
            </field.Label>
            <field.Input
              required
              maxLength="255"
              {{on "invalid" this.validateInputNatively}}
              class="form-control py-2 {{if field.isInvalid 'border-error'}}"
            />
            <field.Errors class="py-1 text-xs text-red-300" />
          </form.Field>
        </div>
        <div class="md:col-span-4 col-span-12 m-2">
          <form.Field @name="origin" as |field|>
            <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
              Origen
              <span class="text-red-500">*</span>
            </field.Label>
            <field.Input
              required
              maxLength="255"
              {{on "invalid" this.validateInputNatively}}
              class="form-control py-2 {{if field.isInvalid 'border-error'}}"
            />
            <field.Errors class="py-1 text-xs text-red-300" />
          </form.Field>
        </div>
        <div class="md:col-span-4 col-span-12 m-2">
          <form.Field @name="amount" as |field|>
            <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
              Importe
              <span class="text-red-500">*</span>
            </field.Label>
            <field.Input
              required
              type="number"
              min="0"
              step="0.01"
              {{on "invalid" this.validateInputNatively}}
              class="form-control py-2 {{if field.isInvalid 'border-error'}}"
            />
            <field.Errors class="py-1 text-xs text-red-300" />
          </form.Field>
        </div>
        <div class="col-span-12 inline-table">
          <form.Field @name="description" @validate={{this.validateHtmlEditor}} as |field|>
            <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
              Description
              <span class="text-red-500">*</span>
            </field.Label>
            <HtmlEditor
              @content={{field.value}}
              @onUpdate={{field.setValue}}
              required="true"
              class="min-h-20 ql-container ql-snow {{if field.isInvalid '!border-error'}}"
            />
            <field.Errors class="py-1 text-xs text-red-300" />
          </form.Field>
        </div>
        <div class="col-span-12">
          <form.Field @name="observations" as |field|>
            <field.Label class="text-dark dark:text-darklink font-semibold block m-2">
              Observaciones Internas
            </field.Label>
            <field.Textarea rows="4" class="py-2.5 px-4 block w-full form-control" />
            <field.Errors class="text-xs text-red-500" />
          </form.Field>
        </div>
      </div>
      <div class="flex gap-3 justify-end mt-6">
        <ButtonText
          type="submit"
          @kind="solid-primary"
          @size="large"
          @isLoading={{this.loading}}
          disabled={{this.loading}}
        >
          Guardar
        </ButtonText>
      </div>
      {{#if this.errors}}
        <div class="flex flex-col p-2 text-sm text-red-500">
          <div>
            {{t "common.error"}}
          </div>
          <div class="flex flex-col">
            {{#each this.errors as |error|}}
              {{#let (get error "path") (get error "message") as |path message|}}
                <div>
                  <span>- {{t (concat "common.form.validation.custom." message) field=path}}</span>
                </div>
              {{/let}}
            {{/each}}
          </div>
        </div>
      {{/if}}
    </HeadlessForm>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
