import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import TripTransportFormComponent from 'vfc-admin/components/forms/TripTransportForm';
import { awaitPromise } from 'vfc-admin/helpers/await-promise';
import ButtonText from 'vfc-admin/components/ui/button/text';
import type ToastService from 'vfc-admin/services/toast-service';
import type TripTransportService from 'vfc-admin/services/trip-transport-service';
import type TripService from 'vfc-admin/services/trip-service';
import type { Extra, Transport, Trip, TripTransportForm } from 'vfc-admin/types/trip';
import { type FormData } from 'ember-headless-form';
import ExtrasFormComponent from '../forms/ExtrasForm';
interface TripTransportTabSignature {
    Element: HTMLDivElement;
    Args: {
        tripPromise: Promise<Trip>;
    };
}
let State = class State {
    @tracked
    result: Trip;
    @tracked
    error: boolean = false;
    @tracked
    isLoading: boolean = true;
};
export default class TripTransportTabComponent extends Component<TripTransportTabSignature> {
    @service
    tripTransportService: TripTransportService;
    @service
    tripService: TripService;
    @service
    toastService: ToastService;
    @tracked
    extras: Array<Extra> = [];
    @tracked
    transport: Transport;
    get isLoading() {
        return this.tripResource.isPending || !this.transport;
    }
    constructor(owner1: unknown, args1: Args<TripTransportTabSignature>){
        super(owner1, args1);
        this.args.tripPromise.then((trip1)=>{
            this.transport = trip1.transport || {};
            this.extras = this.transport?.extras.map((extra1)=>{
                extra1.observations = extra1.observations || '';
                return extra1;
            }) || [];
        });
    }
    get tripResource() {
        return awaitPromise<Trip>(this.args.tripPromise);
    }
    get formTransportData(): TripTransportForm {
        const transport1 = this.transport || {};
        return {
            id: transport1.id || '',
            name: transport1.name || '',
            origin: transport1.origin || '',
            amount: transport1.amount || '',
            description: transport1.description || '',
            observations: transport1.observations || ''
        };
    }
    @action
    async onSubmit(data1: FormData<TripTransportForm>) {
        try {
            const tripId1 = this.tripResource.result.id;
            let promise1 = null;
            if (data1['id']) {
                promise1 = this.tripTransportService.update(tripId1, data1 as TripTransportForm);
            } else {
                promise1 = this.tripTransportService.create(tripId1, data1 as TripTransportForm);
            }
            await promise1.then((data1)=>{
                this.transport = {
                    ...data1
                };
                this.toastService.add({
                    id: crypto.randomUUID(),
                    message: 'El transporte ha sido actualizado',
                    type: 'success',
                    duration: 3000
                });
            }).catch(()=>{
                this.toastService.add({
                    id: crypto.randomUUID(),
                    message: 'Error al actualizar el transporte',
                    type: 'error',
                    duration: 3000
                });
            });
        } catch (error1) {
            console.error(error1);
            throw error1;
        }
    }
    @action
    onAddExtra() {
        const extras1 = [
            ...this.extras
        ];
        extras1.push({
            name: '',
            amount: '',
            observations: ''
        });
        this.extras = extras1;
    }
    @action
    async onNewExtra(extra1: Extra, index1: number): Promise<Extra> {
        const tripId1 = this.tripResource.result.id;
        return this.tripTransportService.newExtra(tripId1, extra1).then((data1)=>{
            this.extras[index1] = data1;
            this.extras = [
                ...this.extras
            ];
            return data1;
        });
    }
    @action
    async onUpdateExtra(extra1: Extra): Promise<Extra> {
        const tripId1 = this.tripResource.result.id;
        return this.tripTransportService.updateExtra(tripId1, extra1);
    }
    @action
    async onRemoveExtra(extra1: Extra): Promise<boolean> {
        const tripId1 = this.tripResource.result.id;
        return this.tripTransportService.removeExtra(tripId1, extra1.id).then(()=>{
            this.extras = [
                ...this.extras.filter((item1)=>item1.id !== extra1.id)
            ];
            return true;
        }).catch(()=>{
            return false;
        });
    }
    static{
        template(`
    {{#if this.isLoading}}
      Loading
    {{else if this.tripResource.isSuccess}}
      {{#unless this.transport.id}}
        <div
          class="bg-lightwarning border border-lightwarning text-sm text-warningemphasis rounded-md p-4 dark:bg-darkwarning dark:border-darkwarning dark:text-warning"
          role="alert"
        >
          <i class="ti ti-alert-triangle text-base leading-tight font-medium"></i>
          <span class="ms-4">
            Este viaje no incluye transporte, añada la información si desea incluirlo
          </span>
        </div>
      {{/unless}}

      <TripTransportFormComponent @form={{{this.formTransportData}}} @onSubmit={{this.onSubmit}} />
      {{#if this.transport.id}}
        <div class="card mt-5">
          <div class="border-b border-light-dark py-4 px-6 flex items-center justify-between">
            <h5 class="card-title">Extras</h5>
            <ButtonText
              type="submit"
              @kind="soft-primary"
              @size="medium"
              {{on "click" this.onAddExtra}}
            >
              <i class="ti ti-plus text-base"></i>
              Nuevo
            </ButtonText>
          </div>
          <div class="card-body">
            {{#if this.extras.length}}
              <ExtrasFormComponent
                @extras={{this.extras}}
                @onNewExtra={{this.onNewExtra}}
                @onUpdateExtra={{this.onUpdateExtra}}
                @onRemoveExtra={{this.onRemoveExtra}}
              />
            {{else}}
              <span>Este transport no incluye extras</span>
            {{/if}}
          </div>

        </div>
      {{/if}}
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
