import Route from '@ember/routing/route';
import { service } from '@ember/service';

import type { Transition } from '@ember/routing';
import type RouterService from '@ember/routing/router-service';
import type ClientService from 'vfc-admin/services/client-service';
import type RelationService from 'vfc-admin/services/relation-service';
import type SchoolService from 'vfc-admin/services/school-service';
import type TripService from 'vfc-admin/services/trip-service';

export default class TripIncomplete extends Route {
  @service declare tripService: TripService;
  @service declare schoolService: SchoolService;
  @service declare clientService: ClientService;
  @service declare relationService: RelationService;
  @service declare router: RouterService;

  async model(params: { id: string }, transition: Transition) {
    // TODO Manage 404 error
    const tripPromise = this.tripService.get(params.id);

    tripPromise.then((trip: Trip) => {
      if (trip.state !== 'incomplete') {
        transition.abort();
        this.router.transitionTo('/not-found');
      }
    });

    // TODO paginate and filter by selector
    const schoolsPromise = this.schoolService.findAll('', { page: 1, pageSize: 0 });
    const clientsPromise = this.clientService.findAll('', { page: 1, pageSize: 0 });
    const clientRelationsPromise = this.relationService.findAll();

    return { tripPromise, clientsPromise, schoolsPromise, clientRelationsPromise };
  }
}
