export interface AccommodationInterface {
  id: number;
  name: string;
  description?: string;
}

export class Accommodation implements AccommodationInterface {
  id!: number;
  name!: string;
  description?: string;

  static parse(data: AccommodationInterface) {
    const accommodation = new Accommodation();
    accommodation.id = data.id;
    accommodation.name = data.name;
    accommodation.description = data.description || undefined;

    return accommodation;
  }
}

export type AccommodationType = Accommodation;
