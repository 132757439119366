import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type TripAccommodationService from 'vfc-admin/services/trip-accommodation-service';
import type { Trip } from 'vfc-admin/types/trip';

export default class TripAccommodation extends Route {
  @service declare tripAccommodationService: TripAccommodationService;

  async model() {
    const { tripId, tripPromise } = this.modelFor('authenticated.trips.pending.trip') as {
      tripId: string;
      tripPromise: Promise<Trip>;
    };

    const accommodationPromise = this.tripAccommodationService.alternatives(tripId);

    return { tripPromise, accommodationPromise };
  }
}
