export const downloadFile = async (response: Response) => {
  response.blob().then((blob: Blob) => {
    const url = window.URL.createObjectURL(blob);
    const pdfViewer = window.open(url, '_blank');
    if (pdfViewer && pdfViewer.document) {
      pdfViewer.document.title = 'TESTING';
    }
  });
};

export const downloadExcel = async (response: Response, name: string) => {
  response.blob().then((blob: Blob) => {
    // Create a temporary URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Create a hidden anchor element to trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = name;
    document.body.appendChild(a);
    a.click();

    // Clean up by revoking the object URL and removing the anchor
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  });
};
