import { template } from "@ember/template-compiler";
import './trip-passengers-tab.css';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { fn, get } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { isBlank } from '@ember/utils';
import dayjs from 'dayjs';
import { HeadlessForm } from 'ember-headless-form';
import { t } from 'ember-intl';
import { modifier } from 'ember-modifier';
import PhWarning from 'ember-phosphor-icons/components/ph-warning';
import { PortalTargets } from 'ember-primitives';
import { use } from 'ember-resources';
import { debounce } from 'reactiveweb/debounce';
import { TrackedObject } from 'tracked-built-ins';
import { TrackedArray } from 'tracked-built-ins';
import { HeadlessFormWrapper } from 'vfc-admin/components/headless-form-wrapper';
import { Accordion } from 'vfc-admin/components/ui/accordion';
import Badge from 'vfc-admin/components/ui/badge';
import ButtonText from 'vfc-admin/components/ui/button/text';
import Loader from 'vfc-admin/components/ui/loader';
import Tooltip from 'vfc-admin/components/ui/tooltip';
import ResponseError from 'vfc-admin/models/response-error';
import Student from 'vfc-admin/models/student';
import Teacher from 'vfc-admin/models/teacher';
import { Notification } from 'vfc-admin/services/notification-service';
import { AuthenticatedRemoteData } from 'vfc-admin/utils/authenticated-remote-data';
import { and, eq, not } from 'vfc-admin/utils/boolean-utils';
import { timeFromNow } from 'vfc-admin/utils/date-utils';
import { MapperComparator, SortUtils } from 'vfc-admin/utils/sort-utils';
import { noSpacesAndLowercase } from 'vfc-admin/utils/string-utils';
import { urlFor } from 'vfc-admin/utils/url-utils';
import { userInitials } from 'vfc-admin/utils/user-utils';
import type Owner from '@ember/owner';
import type RouterService from '@ember/routing/router-service';
import type { Args } from '@glimmer/component/-private/component';
import type { FormData } from 'ember-headless-form';
import type IntlService from 'ember-intl/services/intl';
import type AuthenticatedHttpClient from 'vfc-admin/services/auth/http-client';
import type NotificationService from 'vfc-admin/services/notification-service';
import type PassengerService from 'vfc-admin/services/passenger-service';
import type TripService from 'vfc-admin/services/trip-service';
import type UserService from 'vfc-admin/services/user-service';
import type { Trip } from 'vfc-admin/types/trip';
import { downloadExcel } from 'vfc-admin/utils/file-utils';
type PassengersResponse = {
    state: string;
    totalStudents: number;
    totalTeachers: number;
    students: Array<Student>;
    teachers: Array<Teacher>;
};
let EntityWrapper = class EntityWrapper<T extends Student | Teacher> {
    status: 'complete' | 'incomplete' | 'nofilled' = 'nofilled';
    fieldsNotCompleted: Array<string> = [];
    entity: T;
    constructor(entity1: T){
        this.entity = entity1;
        this.status = this.calculateStatus(entity1);
    }
    calculateStatus(entity1: T) {
        const skipAttrs1 = [
            'allergy',
            'observations'
        ];
        if (!entity1.id) return 'nofilled';
        Object.getOwnPropertyNames(entity1).forEach((attr1)=>{
            if (!skipAttrs1.includes(attr1) && entity1[attr1] === '') {
                this.fieldsNotCompleted.push(attr1);
            }
        });
        if (this.fieldsNotCompleted.length === 0) {
            return 'complete';
        } else {
            return 'incomplete';
        }
    }
};
/**
 * Passengers tab
 */ interface TripPassengersTabSignature {
    Element: HTMLDivElement;
    Args: {
        trip: Trip;
        activeTab: string;
    };
    Blocks: {
        default: [];
    };
}
let TripPassengersTabComponent = class TripPassengersTabComponent extends Component<TripPassengersTabSignature> {
    @service
    intl: IntlService;
    @service
    userService: UserService;
    @service
    passengerService: PassengerService;
    @service
    notificationService: NotificationService;
    @service
    tripService: TripService;
    @service
    router: RouterService;
    formFieldsWithError = new TrackedObject<Record<string, string>>({});
    @use
    authenticatedRemoteData = AuthenticatedRemoteData<PassengersResponse>(()=>urlFor(`/trip/${this.args.trip.id}/passengers`));
    @service('auth/http-client')
    authenticatedHttpClient: AuthenticatedHttpClient;
    students: Array<EntityWrapper<Student>> = new TrackedArray();
    teachers: Array<EntityWrapper<Teacher>> = new TrackedArray();
    @tracked
    activeTab = this.args.activeTab ?? 'students';
    @tracked
    _filterQuery = '';
    inputFilter: HTMLInputElement;
    @tracked
    filterByStatus?: string | null = null;
    @use
    filterQuery = debounce(200, ()=>this._filterQuery);
    @tracked
    errors: Array<unknown>;
    constructor(owner1: Owner, args1: Args<TripPassengersTabSignature>){
        super(owner1, args1);
        this.errors = new TrackedArray();
        this.authenticatedRemoteData.fetchPromise.then((response1)=>{
            Array.from(Array(response1.totalStudents).keys()).map((_value1, index1)=>{
                const student1 = new Student(response1.students[index1]);
                this.students.push(new EntityWrapper(student1));
            });
            Array.from(Array(response1.totalTeachers).keys()).map((_value1, index1)=>{
                const teacher1 = new Teacher(response1.teachers[index1]);
                this.teachers.push(new EntityWrapper(teacher1));
            });
        }).catch((error1: unknown)=>{
            if (error1 instanceof Response) {
                error1.json().then((json1)=>{
                    throw new ResponseError(error1.status, json1);
                });
            } else {
                throw error1;
            }
        });
    }
    anyMatchFilter(listOfAttrs1: Array<string>, compareWith1: string) {
        return listOfAttrs1.some((value1)=>{
            const noSpaceValue1 = noSpacesAndLowercase(value1);
            return value1 && noSpaceValue1.includes(compareWith1);
        });
    }
    strictMatchFilter(listOfAttrs1: Array<string>, compareWith1: string) {
        return listOfAttrs1.some((value1)=>{
            const noSpaceValue1 = noSpacesAndLowercase(value1);
            return value1 && noSpaceValue1 === compareWith1;
        });
    }
    @cached
    get filteredStudents() {
        if (this.isActiveTab('teachers')) return [];
        const students1 = this.students.filter((wrapper1)=>{
            let passfilters1 = true;
            const student1 = wrapper1.entity;
            if (this.filterQuery) {
                const anyMatchFilter1 = this.anyMatchFilter([
                    student1.name,
                    student1.lastName,
                    `${student1.name}${student1.lastName}`,
                    student1.document
                ], this.filterQuery.replace(/\s/g, '').toLowerCase());
                passfilters1 = anyMatchFilter1;
            }
            if (passfilters1 && this.filterByStatus) {
                const statusMatchFilter1 = this.strictMatchFilter([
                    wrapper1.status
                ], this.filterByStatus);
                passfilters1 = statusMatchFilter1;
            }
            return passfilters1;
        });
        SortUtils.sortBy(students1, 'DESC', 'status', MapperComparator({
            nofilled: 2,
            incomplete: 1,
            complete: 0
        }));
        return students1;
    }
    @cached
    get filteredTeachers() {
        if (this.isActiveTab('students')) return [];
        const teachers1 = this.teachers.filter((wrapper1)=>{
            let passfilters1 = true;
            const teacher1 = wrapper1.entity;
            if (this.filterQuery) {
                const anyMatchFilter1 = this.anyMatchFilter([
                    teacher1.name,
                    teacher1.lastName,
                    `${teacher1.name}${teacher1.lastName}`,
                    teacher1.document
                ], this.filterQuery.replace(/\s/g, '').toLowerCase());
                passfilters1 = anyMatchFilter1;
            }
            if (passfilters1 && this.filterByStatus) {
                const statusMatchFilter1 = this.strictMatchFilter([
                    wrapper1.status
                ], this.filterByStatus);
                passfilters1 = statusMatchFilter1;
            }
            return passfilters1;
        });
        return teachers1;
    }
    get totalStudentsCompleted() {
        return this.filteredStudents.reduce((acc1, cv1)=>{
            if (this.isEntityFullyCompleted(cv1)) acc1++;
            return acc1;
        }, 0);
    }
    get totalTeachersCompleted() {
        return this.filteredTeachers.reduce((acc1, cv1)=>{
            if (this.isEntityFullyCompleted(cv1)) acc1++;
            return acc1;
        }, 0);
    }
    @action
    setActiveTab(event1: Event) {
        event1.preventDefault();
        const target1 = event1.target;
        if (target1 instanceof HTMLElement) {
            const dataset1 = target1.dataset;
            if ('tab' in dataset1) {
                const activeTab1 = dataset1['tab'] ?? 'students';
                if (activeTab1 === this.activeTab) return;
                this.activeTab = activeTab1;
                sessionStorage.setItem(`${this.userService.username}.Passengers.activeTab`, this.activeTab);
                this._filterQuery = '';
                this.inputFilter.value = '';
            }
        }
    }
    isActiveTab = (tab1: string)=>{
        return tab1 === this.activeTab;
    };
    registerInputFilter = modifier((element1: HTMLInputElement)=>{
        this.inputFilter = element1;
    });
    @action
    setfilterQuery({ target: target1 }: {
        target: HTMLInputElement;
    }) {
        this._filterQuery = target1.value;
    }
    @action
    setfilterByStatus({ target: target1 }: {
        target: HTMLSelectElement;
    }) {
        const [option1] = target1.selectedOptions;
        this.filterByStatus = option1?.value === 'all' ? null : option1?.value;
    }
    hasError = (fieldName1: string)=>{
        return !!this.formFieldsWithError[fieldName1];
    };
    @action
    validateInputNatively(event1: Event) {
        const input1 = event1.target as HTMLInputElement;
        const validityState1 = input1.validity;
        if (validityState1.valueMissing) {
            input1.setCustomValidity(this.intl.t('common.form.validation.valueMissing'));
        } else if (validityState1.patternMismatch) {
            input1.setCustomValidity(this.intl.t('common.form.validation.patternMismatch'));
        } else if (validityState1.tooShort) {
            input1.setCustomValidity(this.intl.t('common.form.validation.tooShort'));
        } else if (validityState1.tooLong) {
            input1.setCustomValidity(this.intl.t('common.form.validation.tooLong'));
        } else {
            input1.setCustomValidity('');
        }
    }
    @action
    resetCustomValidity(event1: Event) {
        const input1 = event1.target as HTMLInputElement;
        input1.setCustomValidity('');
    }
    validateExpirationDate = (documentExpirationDate1: string)=>{
        if (documentExpirationDate1 && dayjs(documentExpirationDate1).isBefore(dayjs(this.args.trip.checkOut))) {
            return [
                {
                    type: 'documentExpirationDateInvalid',
                    value: documentExpirationDate1,
                    message: this.intl.t('common.form.validation.custom.documentExpirationDateAfterCheckOut')
                }
            ];
        }
    };
    validatePair = (requiredField1: string)=>{
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return (fieldValue1: string, _field1: string, data1: Record<string, string>)=>{
            if (isBlank(data1[requiredField1]) && !isBlank(fieldValue1)) {
                return [
                    {
                        type: 'requiredPairInvalid',
                        value: requiredField1,
                        message: this.intl.t('common.form.validation.custom.requiredPair', {
                            requiredField: this.intl.t(`common.form.fields.${requiredField1}`)
                        })
                    }
                ];
            }
        };
    };
    @action
    handleStudentSubmit(data1: FormData<Student>) {
        const _data1 = data1 as unknown as Student;
        const entity1 = new Student(_data1);
        this.#handleSubmit({
            Klass: Student,
            entity: entity1,
            formDataId: _data1.id,
            wrapperList: this.students
        });
    }
    @action
    deleteStudent(studentId1: string) {
        this.passengerService.deleteStudent(this.args.trip.id, studentId1).then(()=>{
            this.router.refresh(); // TODO import it to avoid refreshing the whole page
        });
    }
    @action
    handleTeacherSubmit(data1: FormData<Teacher>) {
        const _data1 = data1 as unknown as Student;
        const entity1 = new Teacher(data1 as unknown as Teacher);
        this.#handleSubmit({
            Klass: Teacher,
            entity: entity1,
            formDataId: _data1.id,
            wrapperList: this.teachers
        });
    }
    #handleSubmit({ Klass: Klass1, entity: entity1, formDataId: formDataId1, wrapperList: wrapperList1 }: {
        Klass: typeof Teacher | typeof Student;
        entity: Student | Teacher;
        formDataId: string | number | undefined;
        wrapperList: Array<EntityWrapper<Teacher | Student>>;
    }) {
        let promise1;
        let restoreItem1 = {
            index: null,
            item: null
        };
        const urlEntityType1 = Klass1 === Student ? 'students' : 'teachers';
        const headers1 = new Headers({
            'Content-Type': 'application/json'
        });
        if (entity1.id) {
            let index1 = wrapperList1.findIndex((wrapper1)=>formDataId1 === wrapper1.entity.id);
            restoreItem1 = {
                index: index1,
                item: wrapperList1.at(index1),
                message: 'Ha ocurrido un error al actualizar al estudiante'
            };
            entity1.updatedAt = new Date();
            wrapperList1.splice(index1, 1, new EntityWrapper(entity1));
            promise1 = this.authenticatedHttpClient.PUT({
                url: urlFor(`/trip/${this.args.trip.id}/${urlEntityType1}/${entity1.id}`),
                headers: headers1,
                body: Klass1.toPayload(entity1)
            });
        } else {
            let index1 = wrapperList1.findIndex((wp1)=>wp1.entity.temporaryId === entity1.temporaryId);
            restoreItem1 = {
                index: index1,
                item: wrapperList1.at(index1),
                message: 'Ha ocurrido un error al añadir al estudiante'
            };
            entity1.id = entity1.temporaryId;
            wrapperList1.splice(index1, 1, new EntityWrapper(entity1));
            promise1 = this.authenticatedHttpClient.POST<{
                id: string;
            }>({
                url: urlFor(`/trip/${this.args.trip.id}/${urlEntityType1}`),
                headers: headers1,
                body: Klass1.toPayload(entity1)
            });
            promise1.then(async (response1: Response)=>{
                const _entity1 = await response1.json();
                entity1.id = _entity1.id;
                delete entity1['temporaryId'];
            });
        }
        promise1.catch(()=>{
            wrapperList1.splice(restoreItem1.index, 1, restoreItem1.item);
            this.notificationService.notify(new Notification({
                type: 'error',
                message: restoreItem1.message
            }));
        });
        return promise1;
    }
    /*
   * For any reason I dont know, the textarea (innerHTML) is not properly propagated using ember-headless-form
   * so this mutation observer is a workaround to temporary fix it
   */ setupMutationObserverForTextArea = modifier((element1: HTMLTextAreaElement)=>{
        const observer1 = new MutationObserver((mutations1)=>{
            mutations1.forEach((mutation1)=>{
                if (mutation1.type === 'characterData') {
                    const data1 = (mutation1.target as CharacterData).data;
                    (mutation1.target.parentElement as HTMLTextAreaElement).value = data1;
                }
            });
        });
        // Configuration for the observer
        const config1 = {
            characterData: true,
            attributes: false,
            childList: false,
            subtree: true
        };
        // Start observing the textarea for configured mutations
        observer1.observe(element1, config1);
    });
    patternByDocumentType = (pattern1: unknown)=>{
        if (typeof pattern1 !== 'string') throw new Error('expected string based argument');
        const patterns1: Record<string, string> = {
            dni: '[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]',
            nie: '[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]',
            pasaporte: '[0-9]+'
        };
        return patterns1[pattern1];
    };
    @action
    isEntityFullyCompleted(wrapper1: EntityWrapper<Teacher | Student>) {
        return wrapper1.status === 'complete';
    }
    @action
    getAvatarColorByCurrentStatus(wrapper1: EntityWrapper<Teacher | Student>) {
        const classByStatuses1 = {
            nofilled: 'bg-lightprimary',
            complete: 'bg-lightsuccess',
            incomplete: 'bg-lightwarning',
            disabled: 'bg-lightgray'
        };
        return wrapper1.entity.active === 'false' ? classByStatuses1.disabled : classByStatuses1[wrapper1.status];
    }
    removeError = (index1: number)=>{
        this.errors.splice(index1, 1);
    };
    @action
    downloadPassengersList(event1: Event) {
        const eventTarget1 = event1.target as HTMLInputElement;
        eventTarget1.disabled = true;
        eventTarget1.value = 'Cargando...';
        this.tripService.downloadPassengersList(String(this.args.trip.id)).then((response1: Response)=>{
            downloadExcel(response1, `${this.args.trip.reference}-pasajeros.xlsx`);
        }).catch((error1: Error)=>{
        // TODO show an error
        }).finally(()=>{
            eventTarget1.disabled = false;
        });
    }
    static{
        template(`
    {{! @glint-nocheck: not typesafe yet }}
    <section id="passengers" class="px-5 flex flex-col space-y-5 rounded-3xl">
      {{#if this.authenticatedRemoteData.isLoading}}
        <div class="flex justify-center">
          <Loader />
        </div>
      {{else}}
        <div class="flex justify-end">
          <button
            type="button"
            {{on "click" this.downloadPassengersList}}
            class="group/contract btn-md inline-flex items-center gap-x-2 text-sm font-semibold rounded-md border border-transparent bg-lightsecondary text-secondaryemphasis enabled:hover:bg-secondary enabled:hover:text-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 disabled:opacity-50 disabled:bg-lightsecondary"
          >
            <i class="ti ti-file-text text-base group-disabled/contract:hidden"></i>
            <span
              class="animate-spin inline-block size-4 border-[3px] border-current border-t-transparent text-secondaryemphasis enabled:group-hover/contract:text-white rounded-full group-enabled/contract:hidden"
              role="status"
              aria-label="loading"
            ></span>
            Descargar listado
          </button>
        </div>
        <div class="pb-8 px-5">
          <ul
            class="-mb-px flex items-center gap-4 text-sm font-medium"
            {{on "click" this.setActiveTab}}
          >
            <li class="flex-1">
              <a
                data-tab="students"
                href="#"
                class="relative flex items-center justify-center gap-2 px-1 py-3 font-semibold
                  {{if
                    (this.isActiveTab 'students')
                    'text-black after:absolute after:left-0 after:bottom-0 after:h-0.5 after:w-full after:bg-primary'
                    'text-gray-500 hover:text-black'
                  }}"
              >
                Estudiantes
              </a>
            </li>
            <li class="flex-1">
              <a
                data-tab="teachers"
                href="#"
                class="relative flex items-center justify-center gap-2 px-1 py-3 font-semibold
                  {{if
                    (this.isActiveTab 'teachers')
                    'text-black after:absolute after:left-0 after:bottom-0 after:h-0.5 after:w-full after:bg-primary'
                    'text-gray-500 hover:text-black'
                  }}"
              >
                Profesores
              </a>
            </li>
          </ul>
        </div>

        <div
          class="flex flex-col gap-4 sm:flex-row sm:gap-0 justify-between items-center px-6 pb-5"
        >
          <div class="flex flex-col gap-4">
            <input
              {{this.registerInputFilter}}
              class="block w-full text-sm border-b outline-none"
              type="text"
              placeholder="Filtrar por nombre / apellido / num de identifiación"
              {{on "input" this.setfilterQuery}}
            />
            <div class="text-sm flex gap-2">
              <label class="capitalize pl-1" for="passanger-status">
                {{if (this.isActiveTab "students") "estudiantes" "profesores"}}
              </label>
              <select
                class="border-b outline-none"
                id="passanger-status"
                {{on "change" this.setfilterByStatus}}
              >
                <option value="all">
                  todos
                </option>
                <option value="complete">
                  completos
                </option>
                <option value="incomplete">
                  incompletos
                </option>
                <option value="nofilled">
                  sin rellenar
                </option>
              </select>
            </div>
          </div>
          {{#let
            (if
              (this.isActiveTab "students") this.totalStudentsCompleted this.totalTeachersCompleted
            )
            (if
              (this.isActiveTab "students")
              this.filteredStudents.length
              this.filteredTeachers.length
            )
            as |totalCompleted total|
          }}
            <div class="text-xs">
              Completados
              {{totalCompleted}}
              de
              {{total}}
            </div>
          {{/let}}
        </div>

        <div
          class="flex flex-col space-y-2 items-center px-6
            {{if (this.isActiveTab 'students') 'block opacity-100' 'hidden opacity-0'}}"
        >
          {{#each this.filteredStudents as |entityWrapper|}}
            {{#let entityWrapper.entity as |student|}}
              <HeadlessFormWrapper as |wrapper|>
                <Accordion
                  class="student w-full hover:shadow-md {{if student.errors 'error'}}"
                  @closeIfPassCondition={{(fn
                    wrapper.options.evaluateIfDataFormHasChanged student
                  )}}
                  as |accordion|
                >
                  <accordion.Summary
                    class="flex items-center justify-between gap-x-5 px-5
                      {{if student.errors 'error'}}"
                  >
                    <div class="flex items-center gap-x-5">
                      <div class="relative inline-block">
                        <div
                          class="flex items-center justify-center w-10 h-10 rounded-full
                            {{(this.getAvatarColorByCurrentStatus entityWrapper)}}"
                        >
                          {{#if student.id}}
                            <span class="p-3 px-4 text-sm uppercase">
                              {{userInitials student.name}}
                            </span>
                          {{else}}
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 5V19M5 12H19"
                                stroke="#0A1126"
                                stroke-width="1.75"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          {{/if}}
                        </div>
                      </div>
                      <div>
                        {{#if student.id}}
                          <div class="flex gap-4">
                            <div>
                              <div class="line-clamp-1">
                                {{student.name}}
                                {{student.lastName}}
                              </div>
                              <div class="text-xs">
                                Estudiante
                              </div>
                            </div>
                            {{#if (eq student.active "false")}}
                              <span
                                class="inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-lightwarning text-warning dark:bg-darkwarning dark:text-warning"
                              >
                                No activo
                              </span>
                            {{/if}}
                          </div>
                        {{else}}
                          <div class="line-clamp-1">
                            Añada un nuevo estudiante
                          </div>
                        {{/if}}
                      </div>
                    </div>
                    <div class="flex gap-2 lg:gap-4">
                      {{#if (and student.id (not (this.isEntityFullyCompleted entityWrapper)))}}
                        <PortalTargets class="z-10" />
                        <Tooltip class="z-10" @showArrow={{true}}>
                          <:tooltipable as |p|>
                            <Badge @type="warning" {{p.hook}}>
                              <div class="flex gap-2">
                                <PhWarning class="w-4 h-4" />
                                <span class="hidden lg:block">
                                  Incompleto
                                </span>
                              </div>
                            </Badge>
                          </:tooltipable>
                          <:content>
                            <div class="text-xs">
                              <div>
                                Campos incompletos:
                              </div>
                              <div class="flex flex-col">
                                {{#each entityWrapper.fieldsNotCompleted as |field|}}
                                  <span>
                                    &#8208;
                                    {{t
                                      (concat
                                        "page.trips.current-course.trip.passengers-tab.form." field
                                      )
                                    }}
                                  </span>
                                {{/each}}
                              </div>
                            </div>
                          </:content>
                        </Tooltip>
                      {{/if}}
                      <div
                        class="hidden sm:inline-block text-xs
                          {{unless (and student.updatedAt student.id) 'invisible'}}"
                      >
                        <div>
                          Última modificacion
                        </div>
                        <div class="font-medium">
                          {{timeFromNow student.updatedAt}}
                        </div>
                      </div>
                    </div>
                  </accordion.Summary>
                  {{#if accordion.isOpen}}
                    <HeadlessForm
                      {{wrapper.options.registerForm}}
                      @data={{student}}
                      @validateOn="focusout"
                      @revalidateOn="input"
                      @onSubmit={{this.handleStudentSubmit}}
                      {{on "reset" wrapper.options.onResetFormData}}
                      {{on "input" (fn wrapper.options.evaluateIfDataFormHasChanged student)}}
                      as |form|
                    >
                      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 px-2 py-2 gap-4">
                        <div class="flex flex-col gap-1">
                          <form.Field @name="name" as |field|>
                            <field.Label class="text-xs">
                              {{t "page.trips.current-course.trip.passengers-tab.form.name"}}
                              <span class="text-error-500">
                                *
                              </span>
                            </field.Label>
                            <field.Input
                              required
                              {{on "invalid" this.validateInputNatively}}
                              class="{{if
                                  field.isInvalid
                                  'border-red-500'
                                  'focus:border-accent1-300'
                                }}
                                h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                            />
                            <field.Errors class="text-xs text-red-500" />
                          </form.Field>
                        </div>
                        <div class="flex flex-col gap-1">
                          <form.Field @name="lastName" as |field|>
                            <field.Label class="text-xs">
                              {{t "page.trips.current-course.trip.passengers-tab.form.lastName"}}
                              <span class="text-error-500">
                                *
                              </span>
                            </field.Label>
                            <field.Input
                              required
                              {{on "invalid" this.validateInputNatively}}
                              class="{{if
                                  field.isInvalid
                                  'border-red-500'
                                  'focus:border-accent1-300'
                                }}
                                h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                            />
                            <field.Errors class="text-xs text-red-500" />
                          </form.Field>
                        </div>
                        <div class="flex flex-col gap-1">
                          <form.Field @name="birthday" as |field|>
                            <field.Label class="text-xs">
                              {{t "page.trips.current-course.trip.passengers-tab.form.birthday"}}
                              <span class="text-error-500">
                                *
                              </span>
                            </field.Label>
                            <field.Input
                              required
                              type="date"
                              {{on "invalid" this.validateInputNatively}}
                              class="{{if
                                  field.isInvalid
                                  'border-red-500'
                                  'focus:border-accent1-300'
                                }}
                                h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                            />
                            <field.Errors class="text-xs text-red-500" />
                          </form.Field>
                        </div>
                        <div>
                          <form.Field
                            @name="documentType"
                            @validate={{this.validatePair "document"}}
                            as |select|
                          >
                            <select.Label class="text-xs flex flex-col gap-1">
                              <div class="flex gap-2">
                                <span>
                                  {{t
                                    "page.trips.current-course.trip.passengers-tab.form.documentType"
                                  }}
                                </span>
                                <select.Select
                                  required={{and student.id (not (isBlank student.documentType))}}
                                  {{on "invalid" this.validateInputNatively}}
                                  class="{{if select.isInvalid 'border-red-500'}}
                                    -m-1 text-xs border rounded-xl disabled:cursor-not-allowed"
                                  as |select|
                                >
                                  <select.Option @value="">
                                    -- Selecciona una opción --
                                  </select.Option>
                                  <select.Option @value="dni">
                                    DNI
                                  </select.Option>
                                  <select.Option @value="nie">
                                    NIE
                                  </select.Option>
                                  <select.Option @value="pasaporte">
                                    Pasaporte
                                  </select.Option>
                                </select.Select>
                              </div>

                              <form.Field
                                @name="document"
                                @validate={{this.validatePair "documentType"}}
                                as |field|
                              >
                                <field.Input
                                  required={{and student.id (not (isBlank student.document))}}
                                  {{on "invalid" this.validateInputNatively}}
                                  class="{{if
                                      field.isInvalid
                                      'border-red-500'
                                      'focus:border-accent1-300'
                                    }}
                                    h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                                  pattern="{{this.patternByDocumentType select.value}}"
                                />
                                <field.Errors class="text-xs text-red-500" />
                              </form.Field>
                              <select.Errors class="text-xs text-red-500" />
                            </select.Label>
                          </form.Field>
                        </div>
                        <div class="flex flex-col gap-1">
                          <form.Field
                            @name="documentExpirationDate"
                            @validate={{this.validateExpirationDate}}
                            as |field|
                          >
                            <field.Label class="text-xs">
                              {{t
                                "page.trips.current-course.trip.passengers-tab.form.documentExpirationDate"
                              }}
                            </field.Label>
                            <field.Input
                              required={{and
                                student.id
                                (not (isBlank student.documentExpirationDate))
                              }}
                              type="date"
                              {{on "invalid" this.validateInputNatively}}
                              class="{{if
                                  field.isInvalid
                                  'border-red-500'
                                  'focus:border-accent1-300'
                                }}
                                h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                            />
                            <field.Errors class="text-xs text-red-500" />
                          </form.Field>
                        </div>
                        <div class="flex flex-col gap-1">
                          <form.Field @name="documentCountry" as |field|>
                            <field.Label class="text-xs">
                              {{t
                                "page.trips.current-course.trip.passengers-tab.form.documentCountry"
                              }}
                            </field.Label>
                            <field.Input
                              required={{and student.id (not (isBlank student.documentCountry))}}
                              {{on "invalid" this.validateInputNatively}}
                              class="{{if
                                  field.isInvalid
                                  'border-red-500'
                                  'focus:border-accent1-300'
                                }}
                                h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                            />
                            <field.Errors class="text-xs text-red-500" />
                          </form.Field>
                        </div>

                        <div class="flex flex-col gap-1">
                          <form.Field @name="gender" as |field|>
                            <field.Label class="text-xs">
                              {{t "page.trips.current-course.trip.passengers-tab.form.gender"}}
                              <span class="text-error-500">
                                *
                              </span>
                            </field.Label>
                            <field.Select
                              required
                              {{on "invalid" this.validateInputNatively}}
                              class="{{if
                                  field.isInvalid
                                  'border-red-500'
                                  'focus:border-accent1-300'
                                }}
                                h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                              as |select|
                            >
                              <select.Option @value="">
                                -- Selecciona una opción --
                              </select.Option>
                              <select.Option @value="female">
                                Femenino
                              </select.Option>
                              <select.Option @value="male">
                                Masculino
                              </select.Option>
                            </field.Select>
                            <field.Errors class="text-xs text-red-500" />
                          </form.Field>
                        </div>
                        <div class="flex flex-col gap-1">
                          <form.Field @name="active" as |field|>
                            <field.Label class="text-xs">
                              Activo:
                              <span class="text-error-500">
                                *
                              </span>
                            </field.Label>
                            <field.Select
                              required
                              {{on "invalid" this.validateInputNatively}}
                              class="{{if
                                  field.isInvalid
                                  'border-red-500'
                                  'focus:border-accent1-300'
                                }}
                                h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                              as |select|
                            >
                              <select.Option @value="true">
                                Activo
                              </select.Option>
                              <select.Option @value="false">
                                No activo
                              </select.Option>
                            </field.Select>
                            <field.Errors class="text-xs text-red-500" />
                          </form.Field>
                        </div>
                        <div class="flex flex-col col-span-1 sm:col-span-2 md:col-span-3 gap-1">
                          <form.Field @name="allergy" as |field|>
                            <field.Label class="text-xs">
                              {{t "page.trips.current-course.trip.passengers-tab.form.allergy"}}
                            </field.Label>
                            <field.Textarea
                              {{on "invalid" this.validateInputNatively}}
                              {{this.setupMutationObserverForTextArea}}
                              class="{{if
                                  field.isInvalid
                                  'border-red-500'
                                  'focus:border-accent1-300'
                                }}
                                h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                            />
                            <field.Errors class="text-xs text-red-500" />
                          </form.Field>
                        </div>
                        <div class="flex flex-col col-span-1 sm:col-span-2 md:col-span-3 gap-1">
                          <form.Field @name="observations" as |field|>
                            <field.Label class="text-xs">
                              {{t
                                "page.trips.current-course.trip.passengers-tab.form.observations"
                              }}
                            </field.Label>
                            <field.Textarea
                              {{on "invalid" this.validateInputNatively}}
                              {{this.setupMutationObserverForTextArea}}
                              class="{{if
                                  field.isInvalid
                                  'border-red-500'
                                  'focus:border-accent1-300'
                                }}
                                text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                            />
                            <field.Errors class="text-xs text-red-500" />
                          </form.Field>
                        </div>
                      </div>
                      <div class="flex justify-center gap-2 pt-5">
                        <ButtonText type="submit" @kind="solid-primary">
                          {{t "page.trips.current-course.trip.passengers-tab.save-button"}}
                        </ButtonText>
                        {{#if wrapper.options.hasFormDataChanged}}
                          <ButtonText type="reset" @kind="soft-dark">
                            {{t "page.trips.current-course.trip.passengers-tab.reset-button"}}
                          </ButtonText>
                        {{/if}}
                        {{#if student.id}}
                          <ButtonText
                            type="button"
                            @kind="soft-error"
                            {{on "click" (fn this.deleteStudent student.id)}}
                          >
                            {{t "page.trips.current-course.trip.passengers-tab.delete-button"}}
                          </ButtonText>
                        {{/if}}
                      </div>
                      {{#if student.errors}}
                        <div class="flex flex-col p-2 text-sm text-red-500">
                          <div>
                            Ha ocurrido un error
                          </div>
                          <div class="flex flex-col">
                            {{#each student.errors as |error|}}
                              {{#let (get error "path") (get error "message") as |path message|}}
                                <div>
                                  <span>-
                                    {{t
                                      (concat "common.form.validation.custom." message)
                                      field=path
                                    }}</span>
                                </div>
                              {{/let}}
                            {{/each}}
                          </div>
                        </div>
                      {{/if}}
                    </HeadlessForm>
                  {{/if}}
                </Accordion>
              </HeadlessFormWrapper>
            {{/let}}
          {{/each}}
        </div>

        <div
          class="flex flex-col space-y-2 items-center px-6
            {{if (this.isActiveTab 'teachers') 'block opacity-100' 'hidden opacity-0'}}"
        >
          {{#each this.filteredTeachers as |entityWrapper|}}
            {{#let entityWrapper.entity as |teacher|}}
              <HeadlessFormWrapper as |wrapper|>
                <Accordion
                  class="teacher w-full hover:shadow-md {{if teacher.errors 'error'}}"
                  @closeIfPassCondition={{(fn
                    wrapper.options.evaluateIfDataFormHasChanged teacher
                  )}}
                  as |accordion|
                >
                  <accordion.Summary
                    class="flex items-center justify-between gap-x-5 px-5
                      {{unless teacher.id 'bg-[#E6F4F1]/50'}}
                      {{if teacher.errors 'error'}}"
                  >
                    <div class="flex items-center gap-x-5">
                      <div class="relative inline-block">
                        <div
                          class="flex items-center justify-center w-10 h-10 rounded-full
                            {{(this.getAvatarColorByCurrentStatus entityWrapper)}}"
                        >
                          {{#if teacher.id}}
                            <span class="p-3 px-4 text-sm uppercase">
                              {{userInitials teacher.name}}
                            </span>
                          {{else}}
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 5V19M5 12H19"
                                stroke="#0A1126"
                                stroke-width="1.75"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          {{/if}}
                        </div>
                      </div>
                      <div>
                        {{#if teacher.id}}
                          <div class="line-clamp-1">
                            {{teacher.name}}
                            {{teacher.lastName}}
                          </div>
                          <div class="text-xs">
                            Profesor
                          </div>
                        {{else}}
                          <div class="line-clamp-1">
                            Añada un nuevo profesor
                          </div>
                        {{/if}}
                      </div>
                    </div>
                    <div class="flex gap-2 lg:gap-4">
                      {{#if (and teacher.id (not (this.isEntityFullyCompleted entityWrapper)))}}
                        <PortalTargets class="z-10" />
                        <Tooltip class="z-10" @showArrow={{true}}>
                          <:tooltipable as |p|>
                            <Badge @type="warning" {{p.hook}}>
                              <div class="flex gap-2">
                                <PhWarning class="w-4 h-4" />
                                <span class="hidden lg:block">
                                  Incompleto
                                </span>
                              </div>
                            </Badge>
                          </:tooltipable>
                          <:content>
                            <div class="text-xs">
                              <div>
                                Campos incompletos:
                              </div>
                              <div class="flex flex-col">
                                {{#each entityWrapper.fieldsNotCompleted as |field|}}
                                  <span>
                                    &#8208;
                                    {{t
                                      (concat
                                        "page.trips.current-course.trip.passengers-tab.form." field
                                      )
                                    }}
                                  </span>
                                {{/each}}
                              </div>
                            </div>
                          </:content>
                        </Tooltip>
                      {{/if}}
                      <div
                        class="hidden sm:inline-block text-xs
                          {{unless (and teacher.updatedAt teacher.id) 'invisible'}}"
                      >
                        <div>
                          Última modificacion
                        </div>
                        <div class="font-medium">
                          {{timeFromNow teacher.updatedAt}}
                        </div>
                      </div>
                    </div>
                  </accordion.Summary>
                  {{#if accordion.isOpen}}
                    <HeadlessForm
                      {{wrapper.options.registerForm}}
                      @data={{teacher}}
                      @validateOn="focusout"
                      @revalidateOn="input"
                      {{on "reset" wrapper.options.onResetFormData}}
                      {{on "input" (fn wrapper.options.evaluateIfDataFormHasChanged teacher)}}
                      @onSubmit={{this.handleTeacherSubmit}}
                      as |form|
                    >
                      <div class="grid grid-cols-3 px-4 py-2 gap-4">
                        <div class="flex flex-col gap-1">
                          <form.Field @name="name" as |field|>
                            <field.Label class="text-xs">
                              {{t "page.trips.current-course.trip.passengers-tab.form.name"}}
                              <span class="text-error-500">
                                *
                              </span>
                            </field.Label>
                            <field.Input
                              required
                              {{on "invalid" this.validateInputNatively}}
                              class="{{if
                                  field.isInvalid
                                  'border-red-500'
                                  'focus:border-accent1-300'
                                }}
                                h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                            />
                            <field.Errors class="text-xs text-red-500" />
                          </form.Field>
                        </div>
                        <div class="flex flex-col gap-1">
                          <form.Field @name="lastName" as |field|>
                            <field.Label class="text-xs">
                              {{t "page.trips.current-course.trip.passengers-tab.form.lastName"}}
                              <span class="text-error-500">
                                *
                              </span>
                            </field.Label>
                            <field.Input
                              required
                              {{on "invalid" this.validateInputNatively}}
                              class="{{if
                                  field.isInvalid
                                  'border-red-500'
                                  'focus:border-accent1-300'
                                }}
                                h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                            />
                            <field.Errors class="text-xs text-red-500" />
                          </form.Field>
                        </div>
                        <div class="flex flex-col gap-1">
                          <form.Field @name="birthday" as |field|>
                            <field.Label class="text-xs">
                              {{t "page.trips.current-course.trip.passengers-tab.form.birthday"}}
                              <span class="text-error-500">
                                *
                              </span>
                            </field.Label>
                            <field.Input
                              required
                              type="date"
                              {{on "invalid" this.validateInputNatively}}
                              class="{{if
                                  field.isInvalid
                                  'border-red-500'
                                  'focus:border-accent1-300'
                                }}
                                h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                            />
                            <field.Errors class="text-xs text-red-500" />
                          </form.Field>
                        </div>

                        <div class="flex flex-col gap-1">
                          <form.Field @name="gender" as |field|>
                            <field.Label class="text-xs">
                              Género:
                              <span class="text-error-500">
                                *
                              </span>
                            </field.Label>
                            <field.Select
                              required
                              {{on "invalid" this.validateInputNatively}}
                              class="{{if
                                  field.isInvalid
                                  'border-red-500'
                                  'focus:border-accent1-300'
                                }}
                                h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                              as |select|
                            >
                              <select.Option @value="">
                                -- Selecciona una opción --
                              </select.Option>
                              <select.Option @value="female">
                                Femenino
                              </select.Option>
                              <select.Option @value="male">
                                Masculino
                              </select.Option>
                            </field.Select>
                            <field.Errors class="text-xs text-red-500" />
                          </form.Field>
                        </div>
                        <div class="flex flex-col gap-1">
                          <form.Field @name="documentType" as |select|>
                            <select.Label class="text-xs flex flex-col gap-1">
                              <div class="flex gap-2">
                                <span>
                                  {{t
                                    "page.trips.current-course.trip.passengers-tab.form.documentType"
                                  }}
                                </span>
                                <select.Select
                                  required={{and teacher.id (not (isBlank teacher.documentType))}}
                                  {{on "invalid" this.validateInputNatively}}
                                  class="{{if select.isInvalid 'border-red-500'}}
                                    -m-1 text-xs border rounded-xl disabled:cursor-not-allowed"
                                  as |select|
                                >
                                  <select.Option @value="">
                                    -- Selecciona una opción --
                                  </select.Option>
                                  <select.Option @value="dni">
                                    DNI
                                  </select.Option>
                                  <select.Option @value="nie">
                                    NIE
                                  </select.Option>
                                  <select.Option @value="pasaporte">
                                    Pasaporte
                                  </select.Option>
                                </select.Select>
                              </div>

                              <form.Field @name="document" as |field|>
                                <field.Input
                                  required={{and teacher.id (not (isBlank teacher.document))}}
                                  {{on "invalid" this.validateInputNatively}}
                                  class="{{if
                                      field.isInvalid
                                      'border-red-500'
                                      'focus:border-accent1-300'
                                    }}
                                    h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                                  pattern="{{this.patternByDocumentType select.value}}"
                                />
                                <field.Errors class="text-xs text-red-500" />
                              </form.Field>
                              <select.Errors class="text-xs text-red-500" />
                            </select.Label>
                          </form.Field>
                        </div>
                        <div class="flex flex-col gap-1">
                          <form.Field
                            @name="documentExpirationDate"
                            @validate={{this.validateExpirationDate}}
                            as |field|
                          >
                            <field.Label class="text-xs">
                              {{t
                                "page.trips.current-course.trip.passengers-tab.form.documentExpirationDate"
                              }}
                            </field.Label>
                            <field.Input
                              required={{and
                                teacher.id
                                (not (isBlank teacher.documentExpirationDate))
                              }}
                              type="date"
                              {{on "invalid" this.validateInputNatively}}
                              class="{{if
                                  field.isInvalid
                                  'border-red-500'
                                  'focus:border-accent1-300'
                                }}
                                h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                            />
                            <field.Errors class="text-xs text-red-500" />
                          </form.Field>
                        </div>
                        <div class="flex flex-col gap-1">
                          <form.Field @name="documentCountry" as |field|>
                            <field.Label class="text-xs">
                              {{t
                                "page.trips.current-course.trip.passengers-tab.form.documentCountry"
                              }}
                            </field.Label>
                            <field.Input
                              required={{and teacher.id (not (isBlank teacher.documentCountry))}}
                              {{on "invalid" this.validateInputNatively}}
                              class="{{if
                                  field.isInvalid
                                  'border-red-500'
                                  'focus:border-accent1-300'
                                }}
                                h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                            />
                            <field.Errors class="text-xs text-red-500" />
                          </form.Field>
                        </div>
                        <div class="flex flex-col gap-1">
                          <form.Field @name="email" as |field|>
                            <field.Label class="text-xs">
                              Email:
                            </field.Label>
                            <field.Input
                              required
                              {{on "invalid" this.validateInputNatively}}
                              class="{{if
                                  field.isInvalid
                                  'border-red-500'
                                  'focus:border-accent1-300'
                                }}
                                h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                              pattern="[^@ \\t\\r\\n]+@[^@ \\t\\r\\n]+\\.[^@ \\t\\r\\n]+"
                            />
                            <field.Errors class="text-xs text-red-500" />
                          </form.Field>
                        </div>
                        <div class="flex flex-col gap-1">
                          <form.Field @name="phone" as |field|>
                            <field.Label class="text-xs">
                              Teléfono:
                            </field.Label>
                            <field.Input
                              required
                              {{on "invalid" this.validateInputNatively}}
                              type="number"
                              class="{{if
                                  field.isInvalid
                                  'border-red-500'
                                  'focus:border-accent1-300'
                                }}
                                h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                            />
                            <field.Errors class="text-xs text-red-500" />
                          </form.Field>
                        </div>
                        <div class="flex flex-col col-span-3 gap-1 justify-stretch">
                          <form.Field @name="allergy" as |field|>
                            <field.Label class="text-xs">
                              {{t "page.trips.current-course.trip.passengers-tab.form.allergy"}}
                            </field.Label>
                            <field.Textarea
                              {{on "invalid" this.validateInputNatively}}
                              {{this.setupMutationObserverForTextArea}}
                              class="{{if
                                  field.isInvalid
                                  'border-red-500'
                                  'focus:border-accent1-300'
                                }}
                                h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                            />
                            <field.Errors class="text-xs text-red-500" />
                          </form.Field>
                        </div>
                        <div class="flex flex-col col-span-3 gap-1">
                          <form.Field @name="observations" as |field|>
                            <field.Label class="text-xs">
                              {{t
                                "page.trips.current-course.trip.passengers-tab.form.observations"
                              }}
                            </field.Label>
                            <field.Textarea
                              {{on "invalid" this.validateInputNatively}}
                              {{this.setupMutationObserverForTextArea}}
                              class="{{if
                                  field.isInvalid
                                  'border-red-500'
                                  'focus:border-accent1-300'
                                }}
                                text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                            />
                            <field.Errors class="text-xs text-red-500" />
                          </form.Field>
                        </div>
                      </div>
                      <div class="flex justify-center gap-2 pt-5">
                        <ButtonText type="submit" @kind="solid-primary">
                          {{t "page.trips.current-course.trip.passengers-tab.save-button"}}
                        </ButtonText>
                        {{#if wrapper.options.hasFormDataChanged}}
                          <ButtonText type="reset" @kind="soft-dark">
                            {{t "page.trips.current-course.trip.passengers-tab.reset-button"}}
                          </ButtonText>
                        {{/if}}
                      </div>
                    </HeadlessForm>
                  {{/if}}
                </Accordion>
              </HeadlessFormWrapper>
            {{/let}}
          {{/each}}
        </div>
      {{/if}}
    </section>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export { TripPassengersTabComponent };
