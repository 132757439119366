import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { on } from '@ember/modifier';
import { action, get } from '@ember/object';
import { service } from '@ember/service';
import { type FormData, HeadlessForm } from 'ember-headless-form';
import { t } from 'ember-intl';
import Combobox, { ComboboxItem } from 'vfc-admin/components/inputs/combobox/combobox';
import { HtmlEditor } from 'vfc-admin/components/ui/html-editor';
import { awaitPromise } from 'vfc-admin/helpers/await-promise';
import { noop } from 'vfc-admin/utils/fn-utils';
import { validateInputNatively } from 'vfc-admin/utils/form-utils';
import { concat } from 'vfc-admin/utils/string-utils';
import ButtonText from 'vfc-admin/components/ui/button/text';
import type RouterService from '@ember/routing/router-service';
import type { TripAccommodationForm } from 'vfc-admin/types/trip';
import type { Accommodation } from 'vfc-admin/models/Accommodation';
export interface TripAccommodationFormSignature {
    Element: HTMLElement;
    Args: {
        form?: TripAccommodationForm;
        accommodationPromise: Promise<Accommodation>;
        onResetForm?: (event: Event) => void;
        onSubmit: (data: FormData<TripAccommodationForm>) => Promise<void>;
    };
}
export default class TripAccommodationFormComponent extends Component<TripAccommodationFormSignature> {
    @service
    router: RouterService;
    validateInputNatively = (event1: Event)=>validateInputNatively.call(this, event1);
    @tracked
    errors = [];
    @tracked
    loading: boolean = false;
    get accommodationResource() {
        return awaitPromise<Accommodation>(this.args.accommodationPromise);
    }
    get accommodationComboboxItems() {
        return this.accommodationResource.result?.data.map((accommodation1)=>new ComboboxItem(`${accommodation1.name}`, accommodation1.reference));
    }
    @action
    onSubmit(data1) {
        this.loading = true;
        this.args.onSubmit(data1).catch((error1)=>{
            if (error1 instanceof Response) {
                error1.json().then((data1)=>{
                    this.errors = data1.errors;
                });
            }
        }).finally(()=>{
            this.loading = false;
        });
    }
    static{
        template(`
    <HeadlessForm
      @data={{@form}}
      @validateOn="focusout"
      @revalidateOn="input"
      @onSubmit={{this.onSubmit}}
      {{on "reset" (if @onResetForm @onResetForm noop)}}
      as |form|
    >
      <div class="grid grid-cols-12 md:gap-6 gap-y-3">
        <div class="md:col-span-5 col-span-12 m-2">
          <form.Field @name="country" as |field|>
            <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
              País
              <span class="text-red-500">*</span>
            </field.Label>
            <field.Input required disabled="true" class="form-control py-2 disabled:bg-slate-50" />
          </form.Field>
        </div>
        <div class="md:col-span-5 col-span-12 m-2">
          <form.Field @name="city" as |field|>
            <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
              Ciudad
              <span class="text-red-500">*</span>
            </field.Label>
            <field.Input required disabled="true" class="form-control disabled:bg-slate-50 py-2" />
          </form.Field>
        </div>
        <div class="md:col-span-4 col-span-12 m-2">
          <form.Field @name="accommodationReference" as |field|>
            <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
              Alojamiento
              <span class="text-red-500">*</span>
            </field.Label>
            <Combobox
              @value={{field.value}}
              @onChange={{field.setValue}}
              id={{field.id}}
              @placeholder="-- Seleccionar alojammiento --"
              @isLoading={{this.accommodationResource.isPending}}
              @items={{this.accommodationComboboxItems}}
              @invalid={{field.isInvalid}}
              name="clientId"
              required
            />
          </form.Field>
        </div>
        <div class="md:col-span-4 col-span-12 m-2">
          <form.Field @name="meal" as |field|>
            <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
              Régimen de comida
              <span class="text-red-500">*</span>
            </field.Label>
            <field.Input
              required
              maxLength="255"
              {{on "invalid" this.validateInputNatively}}
              class="form-control py-2 {{if field.isInvalid 'border-error'}}"
            />
            <field.Errors class="py-1 text-xs text-red-300" />
          </form.Field>
        </div>
        <div class="md:col-span-4 col-span-12 m-2">
          <form.Field @name="amount" as |field|>
            <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
              Importe
              <span class="text-red-500">*</span>
            </field.Label>
            <field.Input
              required
              type="number"
              min="0"
              step="0.01"
              {{on "invalid" this.validateInputNatively}}
              class="form-control py-2 {{if field.isInvalid 'border-error'}}"
            />
            <field.Errors class="py-1 text-xs text-red-300" />
          </form.Field>
        </div>
        <div class="col-span-12 inline-table">
          <form.Field @name="description" as |field|>
            <HtmlEditor
              @content={{field.value}}
              @onUpdate={{field.setValue}}
              class="min-h-20 ql-container ql-snow {{if field.isInvalid '!border-error'}}"
            />
            <field.Errors class="py-1 text-xs text-red-300" />
          </form.Field>
        </div>
        <div class="col-span-12">
          <form.Field @name="observations" as |field|>
            <field.Label class="text-dark dark:text-darklink font-semibold block m-2">
              Observaciones Internas
            </field.Label>
            <field.Textarea rows="4" class="py-2.5 px-4 block w-full form-control" />
            <field.Errors class="text-xs text-red-500" />
          </form.Field>
        </div>
      </div>
      <div class="flex gap-3 justify-end mt-6">
        <ButtonText
          type="submit"
          @kind="solid-primary"
          @isLoading={{this.loading}}
          disabled={{this.loading}}
        >
          Guardar
        </ButtonText>
      </div>
      {{#if this.errors}}
        <div class="flex flex-col p-2 text-sm text-red-500">
          <div>
            {{t "common.error"}}
          </div>
          <div class="flex flex-col">
            {{#each this.errors as |error|}}
              {{#let (get error "path") (get error "message") as |path message|}}
                <div>
                  <span>- {{t (concat "common.form.validation.custom." message) field=path}}</span>
                </div>
              {{/let}}
            {{/each}}
          </div>
        </div>
      {{/if}}
    </HeadlessForm>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
