import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { on } from '@ember/modifier';
import { action, get } from '@ember/object';
import { service } from '@ember/service';
import { type FormData, HeadlessForm } from 'ember-headless-form';
import { t } from 'ember-intl';
import Combobox, { ComboboxItem } from 'vfc-admin/components/inputs/combobox/combobox';
import { awaitPromise } from 'vfc-admin/helpers/await-promise';
import { noop } from 'vfc-admin/utils/fn-utils';
import { validateInputNatively } from 'vfc-admin/utils/form-utils';
import { concat } from 'vfc-admin/utils/string-utils';
import type RouterService from '@ember/routing/router-service';
import type { TripForm } from 'vfc-admin/types/trip';
import type { Client } from 'vfc-admin/models/Client';
import type { School } from 'vfc-admin/models/School';
import type { ClientRelation } from 'vfc-admin/types/trip';
import { isPresent } from '@ember/utils';
export interface TripFormComponentSignature {
    Element: HTMLElement;
    Args: {
        tripForm?: TripForm;
        clientPromise?: Promise<Client>;
        schoolPromise?: Promise<School>;
        clientRelationsPromise?: Promise<ClientRelation>;
        onResetForm?: (event: Event) => void;
        onSubmit?: (data: FormData<TripForm>) => void;
    };
}
export default class TripFormComponent extends Component<TripFormComponentSignature> {
    @service
    router: RouterService;
    validateInputNatively = (event1: Event)=>validateInputNatively.call(this, event1);
    @tracked
    clientId = this.args.tripForm?.clientId;
    @tracked
    schoolId = this.args.tripForm?.schoolId;
    @tracked
    errors = [];
    get clientResource() {
        return awaitPromise<Client>(this.args.clientPromise);
    }
    get schoolResource() {
        return awaitPromise<School>(this.args.schoolPromise);
    }
    get clientRelationResource() {
        return awaitPromise<ClientRelation>(this.args.clientRelationsPromise);
    }
    get clientComboboxItems() {
        return this.clientResource.result?.data.map((client1)=>new ComboboxItem(`${client1.name} (${client1.email})`, client1.id));
    }
    get schoolComboboxItems() {
        return this.schoolResource.result?.data.map((school1)=>new ComboboxItem(`${school1.name} (${school1.province})`, school1.id));
    }
    @action
    onSubmit(data1) {
        this.args.onSubmit(data1).catch((error1)=>{
            if (error1 instanceof Response) {
                error1.json().then((data1)=>{
                    this.errors = data1.errors;
                });
            }
        });
    }
    static{
        template(`
    <HeadlessForm
      @data={{@tripForm}}
      @validateOn="focusout"
      @revalidateOn="input"
      @onSubmit={{this.onSubmit}}
      {{on "reset" (if @onResetForm @onResetForm noop)}}
      as |form|
    >
      <div class="grid grid-cols-12 md:gap-6 gap-y-3">
        <div class="md:col-span-4 col-span-12">
          <form.Field @name="reference" as |field|>
            <field.Label
              class="text-dark dark:text-darklink font-semibold block m-2"
            >Referencia</field.Label>
            <field.Input
              type="text"
              placeholder="Referencia"
              class="py-2.5 px-4 block w-full form-control disabled:bg-slate-50 text-dark"
              disabled
            />
          </form.Field>
        </div>
        <div class="md:col-span-4 col-span-12">
          <form.Field @name="accept_conditions" as |field|>
            <field.Label class="text-dark dark:text-darklink font-semibold block m-2">Acepta
              condiciones</field.Label>
            <field.Input
              type="text"
              placeholder="Acepta condiciones"
              class="py-2.5 px-4 block w-full form-control disabled:bg-slate-50 text-dark"
              disabled
            />
          </form.Field>
        </div>
        <div class="md:col-span-4 col-span-12">
          <form.Field @name="accepted_date" as |field|>
            <field.Label class="text-dark dark:text-darklink font-semibold block m-2">Fecha y hora</field.Label>
            <field.Input
              type="date"
              placeholder="--"
              class="py-2.5 px-4 block w-full form-control disabled:bg-slate-50 text-dark"
              disabled
            />
          </form.Field>
        </div>
      </div>
      <fieldset class="my-4 border p-4 rounded">
        <legend class="text-dark dark:text-darklink font-semibold text-lg">Configuración Cliente</legend>
        <div class="grid grid-cols-12 md:gap-6 gap-y-3">
          <div class="md:col-span-4 col-span-12">
            <form.Field @name="modifyClientData" as |field|>
              <field.Label class="text-dark dark:text-darklink font-semibold block m-2">Modificar
                Datos</field.Label>
              <field.Select class="py-2.5 px-4 pe-9 form-control" as |select|>
                <select.Option @value="all">Todos</select.Option>
                <select.Option @value="all-except-name-and-surname">Todos excepto Nombre y Apellidos</select.Option>
                <select.Option @value="none">Ninguno</select.Option>
              </field.Select>
              <field.Errors class="text-xs text-red-500" />
            </form.Field>
          </div>
        </div>
      </fieldset>
      <fieldset class="my-4 border p-4 rounded">
        <legend class="text-dark dark:text-darklink font-semibold text-lg">
          <a
            href="/clients/{{this.args.tripForm.clientId}}"
            class="text-info edit flex items-center gap-2"
          >
            <span>Datos Cliente</span>
            <i class="ti ti-eye text-lg"></i>
          </a>
        </legend>
        <div class="grid grid-cols-12 md:gap-6 gap-y-3">
          <div class="md:col-span-6 col-span-12">
            <form.Field @name="clientId" as |field|>
              <field.Label
                class="text-dark dark:text-darklink font-semibold block m-2"
              >Cliente</field.Label>
              <Combobox
                @value={{field.value}}
                @onChange={{field.setValue}}
                id={{field.id}}
                @placeholder="-- Selecciona una opción --"
                @isLoading={{this.clientResource.isPending}}
                @items={{this.clientComboboxItems}}
                @invalid={{field.isInvalid}}
                name="clientId"
                required
              />
            </form.Field>
          </div>
          <div class="md:col-span-4 col-span-12">
            <form.Field @name="clientRelationId" as |field|>
              <field.Label class="text-dark dark:text-darklink font-semibold block m-2">Quien eres</field.Label>
              <field.Select
                class="py-2.5 px-4 pe-9 form-control disabled:bg-slate-50 text-dark"
                as |select|
              >
                {{#if this.clientRelationResource.isPending}}
                  <select.Option @value="none">...Cargando</select.Option>
                {{else if (isPresent this.clientRelationResource.result)}}
                  {{#each this.clientRelationResource.result as |relation|}}
                    <select.Option @value="{{relation.id}}">{{relation.name}}</select.Option>
                  {{/each}}

                {{/if}}
              </field.Select>
            </form.Field>
          </div>
        </div>
      </fieldset>
      <fieldset class="my-4 border p-4 rounded">
        <legend class="text-dark dark:text-darklink font-semibold text-lg">
          <a
            href="/schools/{{this.args.tripForm.schoolId}}"
            class="text-info edit flex items-center gap-2"
          >
            <span>Datos Colegio</span>
            <i class="ti ti-eye text-lg"></i>
          </a>
        </legend>
        <div class="grid grid-cols-12 md:gap-6 gap-y-3">
          <div class="md:col-span-6 col-span-12">
            <form.Field @name="schoolId" as |field|>
              <field.Label
                class="text-dark dark:text-darklink font-semibold block m-2"
              >Colegio</field.Label>
              <Combobox
                @value={{field.value}}
                @onChange={{field.setValue}}
                id={{field.id}}
                @placeholder="-- Selecciona una opción --"
                @isLoading={{this.schoolResource.isPending}}
                @items={{this.schoolComboboxItems}}
                @invalid={{field.isInvalid}}
                name="schoolId"
                required
              />
            </form.Field>
          </div>
        </div>
      </fieldset>
      <fieldset class="my-4 border p-4 rounded">
        <legend class="text-dark dark:text-darklink font-semibold text-lg">Datos Viaje</legend>
        <div class="grid grid-cols-12 md:gap-6 gap-y-3">
          <div class="md:col-span-4 col-span-12">
            <form.Field @name="state" as |field|>
              <field.Label
                class="text-dark dark:text-darklink font-semibold block m-2"
              >Estado</field.Label>
              <field.Select
                type="text"
                class="py-2.5 px-4 block w-full form-control disabled:bg-slate-50 text-dark"
                as |select|
              >
                <select.Option @value="pending">Pendiente</select.Option>
                <select.Option @value="inprogress">En trámite</select.Option>
                <select.Option @value="closed">Cerrado</select.Option>
                <select.Option @value="cancelled">Cancelado</select.Option>
              </field.Select>
              <field.Errors class="text-xs text-red-500" />
            </form.Field>
          </div>
          <div class="md:col-span-4 col-span-12">
            <form.Field @name="destination_name" as |field|>
              <field.Label
                class="text-dark dark:text-darklink font-semibold block m-2"
              >Destino</field.Label>
              <field.Input
                type="text"
                placeholder="Destino"
                class="py-2.5 px-4 block w-full form-control disabled:bg-slate-50 text-dark"
                disabled
              />
              <field.Errors class="text-xs text-red-500" />
            </form.Field>
          </div>
          <div class="md:col-span-4 col-span-12">
            <form.Field @name="requestedCheckIn" as |field|>
              <field.Label class="text-dark dark:text-darklink font-semibold block m-2">Fecha Ida
                Formulario</field.Label>
              <field.Input
                type="text"
                placeholder="Fecha Ida Formulario"
                class="py-2.5 px-4 block w-full form-control disabled:bg-slate-50 text-dark"
                disabled
              />
            </form.Field>
          </div>
          <div class="md:col-span-4 col-span-12">
            <form.Field @name="checkIn" as |field|>
              <field.Label class="text-dark dark:text-darklink font-semibold block m-2">Fecha Ida
                Viaje</field.Label>
              <field.Input
                type="date"
                placeholder="Fecha Ida Viaje"
                class="py-2.5 px-4 block w-full form-control"
                {{on "invalid" this.validateInputNatively}}
              />
              <field.Errors class="text-xs text-red-500" />
            </form.Field>
          </div>
          <div class="md:col-span-4 col-span-12">
            <form.Field @name="requestedCheckOut" as |field|>
              <field.Label class="text-dark dark:text-darklink font-semibold block m-2">Fecha Vuelta
                Formulario</field.Label>
              <field.Input
                type="text"
                placeholder="Fecha Vuelta Formulario"
                class="py-2.5 px-4 block w-full form-control disabled:bg-slate-50 text-dark"
                disabled
              />
            </form.Field>
          </div>
          <div class="md:col-span-4 col-span-12">
            <form.Field @name="checkOut" as |field|>
              <field.Label class="text-dark dark:text-darklink font-semibold block m-2">Fecha Vuelta
                Viaje</field.Label>
              <field.Input
                type="date"
                placeholder="Fecha Vuelta Viaje"
                class="py-2.5 px-4 block w-full form-control"
                {{on "invalid" this.validateInputNatively}}
              />
              <field.Errors class="text-xs text-red-500" />
            </form.Field>
          </div>
          <div class="md:col-span-4 col-span-12">
            <form.Field @name="totalRequestedStudents" as |field|>
              <field.Label class="text-dark dark:text-darklink font-semibold block m-2">Numero
                Estudiantes Formulario</field.Label>
              <field.Input
                type="number"
                placeholder="Numero Estudiantes Formulario"
                class="py-2.5 px-4 block w-full form-control disabled:bg-slate-50 text-dark"
                disabled
              />
            </form.Field>
          </div>
          <div class="md:col-span-4 col-span-12">
            <form.Field @name="totalStudents" as |field|>
              <field.Label class="text-dark dark:text-darklink font-semibold block m-2">Numero
                Estudiantes</field.Label>
              <field.Input
                type="number"
                placeholder="Numero Estudantes"
                class="py-2.5 px-4 block w-full form-control"
                {{on "invalid" this.validateInputNatively}}
              />
              <field.Errors class="text-xs text-red-500" />
            </form.Field>
          </div>
          <div class="md:col-span-4 col-span-12">
            <form.Field @name="totalRequestedTeachers" as |field|>
              <field.Label class="text-dark dark:text-darklink font-semibold block m-2">Numero
                Profesores Formulario</field.Label>
              <field.Input
                type="number"
                placeholder="Numero Profesores Formulario"
                class="py-2.5 px-4 block w-full form-control disabled:bg-slate-50 text-dark"
                disabled
              />
            </form.Field>
          </div>
          <div class="md:col-span-4 col-span-12">
            <form.Field @name="totalTeachers" as |field|>
              <field.Label class="text-dark dark:text-darklink font-semibold block m-2">Numero
                Profesores</field.Label>
              <field.Input
                type="number"
                placeholder="Numero Profesores"
                class="py-2.5 px-4 block w-full form-control"
                {{on "invalid" this.validateInputNatively}}
              />
              <field.Errors class="text-xs text-red-500" />
            </form.Field>
          </div>
          <div class="md:col-span-4 col-span-12">
            <form.Field @name="groupAge" as |field|>
              <field.Label class="text-dark dark:text-darklink font-semibold block m-2">Edad grupo</field.Label>
              <field.Input
                type="text"
                placeholder="Edad grupo"
                class="py-2.5 px-4 block w-full form-control disabled:bg-slate-50 text-dark"
                disabled
              />
            </form.Field>
          </div>
          <div class="md:col-span-4 col-span-12">
            <form.Field @name="totalPrice" as |field|>
              <field.Label class="text-dark dark:text-darklink font-semibold block m-2">Presupuesto
                Viaje</field.Label>
              <field.Input
                type="number"
                step="0.01"
                placeholder="Presupuesto Viaje"
                class="py-2.5 px-4 block w-full form-control disabled:bg-slate-50 text-dark"
                disabled
              />
            </form.Field>
          </div>
          <div class="col-span-12">
            <form.Field @name="anythingElse" as |field|>
              <field.Label class="text-dark dark:text-darklink font-semibold block m-2">Algo mas</field.Label>
              <field.Textarea
                placeholder="Algo mas"
                class="py-2.5 px-4 block w-full form-control disabled:bg-slate-50 text-dark"
                disabled
              />
            </form.Field>
          </div>
        </div>
      </fieldset>
      <div class="grid grid-cols-12 md:gap-6 gap-y-3">
        <div class="col-span-12">
          <form.Field @name="observations" as |field|>
            <field.Label class="text-dark dark:text-darklink font-semibold block m-2">Observaciones
              Internas</field.Label>
            <field.Textarea
              placeholder="Observaciones Internas"
              rows="8"
              class="py-2.5 px-4 block w-full form-control"
            />
            <field.Errors class="text-xs text-red-500" />
          </form.Field>
        </div>
      </div>
      <div class="flex gap-3 justify-end mt-6">
        <button class="btn btn-md" type="submit" disabled={{form.submissionState.isPending}}>
          Guardar
        </button>
        <button class="btn btn-light-error" type="reset">Cancelar</button>
      </div>
      {{#if this.errors}}
        <div class="flex flex-col p-2 text-sm text-red-500">
          <div>
            {{t "common.error"}}
          </div>
          <div class="flex flex-col">
            {{#each this.errors as |error|}}
              {{#let (get error "path") (get error "message") as |path message|}}
                <div>
                  <span>- {{t (concat "common.form.validation.custom." message) field=path}}</span>
                </div>
              {{/let}}
            {{/each}}
          </div>
        </div>
      {{/if}}
    </HeadlessForm>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
