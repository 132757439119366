import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { on } from '@ember/modifier';
import { type FormData, HeadlessForm } from 'ember-headless-form';
import { formatDate } from 'vfc-admin/utils/date-utils';
import { validateInputNatively } from 'vfc-admin/utils/form-utils';
import type { TripPaymentScheduledForm } from 'vfc-admin/models/payments';
export interface TripPaymentScheduledFormSignature {
    Element: HTMLElement;
    Args: {
        scheduledPayment: TripPaymentScheduledForm;
        onSubmit: (data: FormData<TripPaymentScheduledForm>) => void;
        onClose: (event: MouseEvent) => void;
    };
}
export default class TripPaymentScheduledFormComponent extends Component<TripPaymentScheduledFormSignature> {
    validateInputNatively = (event1: Event)=>validateInputNatively.call(this, event1);
    static{
        template(`
    <HeadlessForm
      class="space-y-4"
      @data={{@scheduledPayment}}
      @onSubmit={{@onSubmit}}
      @validateOn="focusout"
      @revalidateOn="input"
      as |form|
    >
      <div>
        <form.Field @name="description" as |field|>
          <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
            Descripción
            <span class="text-red-500">*</span>
          </field.Label>
          <field.Input
            required
            maxLength="255"
            {{on "invalid" this.validateInputNatively}}
            class="form-control py-2 {{if field.isInvalid 'border-error'}}"
          />
          <field.Errors class="py-1 text-xs text-red-300" />
        </form.Field>
      </div>
      <div>
        <form.Field @name="date" as |field|>
          <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
            Fecha de pago
            <span class="text-red-500">*</span>
          </field.Label>
          <field.Input
            required
            type="date"
            value="{{if @scheduledPayment.date (formatDate @scheduledPayment.date 'YYYY-MM-DD')}}"
            {{on "invalid" this.validateInputNatively}}
            class="form-control py-2 {{if field.isInvalid 'border-error'}}"
          />
          <field.Errors class="py-1 text-xs text-red-300" />
        </form.Field>
      </div>
      <div>
        <form.Field @name="amount" as |field|>
          <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
            Importe
            <span class="text-red-500">*</span>
          </field.Label>
          <field.Input
            required
            type="number"
            min="0"
            step="0.01"
            {{on "invalid" this.validateInputNatively}}
            class="form-control py-2 {{if field.isInvalid 'border-error'}}"
          />
          <field.Errors class="py-1 text-xs text-red-300" />
        </form.Field>
      </div>
      <div>
        <form.Field @name="observations" as |field|>
          <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
            Observaciones
          </field.Label>
          <field.Textarea class="form-control py-2 {{if field.isInvalid 'border-error'}}" />
          <field.Errors class="py-1 text-xs text-red-300" />
        </form.Field>
      </div>
      <div class="flex gap-3 justify-end">
        <button class="btn btn-md" type="submit">Guardar</button>
        <button type="button" {{on "click" @onClose}} class="btn btn-light-error">
          Cancelar
        </button>
      </div>
    </HeadlessForm>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
