import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { assert } from '@ember/debug';
import { fn } from '@ember/helper';
import { get } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { gte } from '@ember/object/computed';
import { service } from '@ember/service';
import { HeadlessForm } from 'ember-headless-form';
import { cell, resource } from 'ember-resources';
import { TrackedArray } from 'tracked-built-ins';
import TripAccommodationFormComponent from 'vfc-admin/components/forms/tripAccommodationForm';
import ButtonText from 'vfc-admin/components/ui/button/text';
import { awaitPromise } from 'vfc-admin/helpers/await-promise';
import { eq, not } from 'vfc-admin/utils/boolean-utils';
import { downloadFile } from 'vfc-admin/utils/file-utils';
import { sum } from 'vfc-admin/utils/math-utils';
import ExtrasFormComponent from '../forms/ExtrasForm';
import type { Args } from '@glimmer/component/-private/component';
import type { Accommodation } from 'vfc-admin/models/Accommodation';
import type ToastService from 'vfc-admin/services/toast-service';
import type TripAccommodationService from 'vfc-admin/services/trip-accommodation-service';
import type TripService from 'vfc-admin/services/trip-service';
import type { Extra, Trip, TripAccommodationForm } from 'vfc-admin/types/trip';
interface TripAccommodationTabSignature {
    Element: HTMLDivElement;
    Args: {
        tripPromise: Promise<Trip>;
        accommodationPromise: Promise<Accommodation>;
    };
}
let State = class State {
    @tracked
    result: Trip | null = null;
    @tracked
    error: boolean = false;
    @tracked
    isLoading: boolean = true;
};
export default class TripAccommodationTabComponent extends Component<TripAccommodationTabSignature> {
    @service
    tripAccommodationService: TripAccommodationService;
    @service
    tripService: TripService;
    @service
    toastService: ToastService;
    @tracked
    extras = new TrackedArray<Extra>([]);
    constructor(owner1: unknown, args1: Args<TripAccommodationTabSignature>){
        super(owner1, args1);
        this.args.tripPromise.then((trip1)=>{
            this.extras = trip1.accommodation?.extras.map((extra1)=>{
                extra1.observations = extra1.observations || '';
                return extra1;
            }) || [];
        });
    }
    get tripResource() {
        return awaitPromise(this.args.tripPromise);
    }
    get formAccommodationData(): TripAccommodationForm {
        const trip1 = this.tripResource.result;
        if (!trip1) return {} as TripAccommodationForm;
        return {
            id: trip1.accommodation.id,
            accommodationReference: trip1.accommodation.details.reference,
            amount: trip1.accommodation.amount,
            country: trip1.destination.city.country.name,
            city: trip1.destination.city.name,
            meal: trip1.accommodation.meal,
            description: trip1.accommodation.description || '',
            observations: trip1.accommodation.observations || ''
        };
    }
    @action
    async onSubmit(data1: FormData<tripAccommodationForm>) {
        try {
            const tripId1 = this.tripResource.result.id;
            await this.tripAccommodationService.update(tripId1, data1 as TripAccommodationForm).then(()=>{
                this.toastService.add({
                    id: crypto.randomUUID(),
                    message: 'El alojamiento ha sido actualizado',
                    type: 'success',
                    duration: 3000
                });
            }).catch(()=>{
                this.toastService.add({
                    id: crypto.randomUUID(),
                    message: 'Error al actualizar el alojamiento',
                    type: 'error',
                    duration: 3000
                });
            });
        } catch (error1) {
            console.error(error1);
            throw error1;
        }
    }
    @action
    onAddExtra() {
        const extras1 = [
            ...this.extras
        ];
        extras1.push({
            name: '',
            amount: '',
            observations: ''
        });
        this.extras = extras1;
    }
    @action
    async onNewExtra(extra1: Extra, index1: number) {
        const tripId1 = this.tripResource.result.id;
        return await this.tripAccommodationService.newExtra(tripId1, extra1).then((data1)=>{
            this.extras[index1] = data1;
            this.extras = [
                ...this.extras
            ];
        });
    }
    @action
    async onUpdateExtra(extra1: Extra) {
        const tripId1 = this.tripResource.result.id;
        return this.tripAccommodationService.updateExtra(tripId1, extra1);
    }
    @action
    async onRemoveExtra(extra1: Extra) {
        const tripId1 = this.tripResource.result.id;
        return this.tripAccommodationService.removeExtra(tripId1, extra1.id).then(()=>{
            this.extras = [
                ...this.extras.filter((item1)=>item1.id !== extra1.id)
            ];
            return true;
        }).catch(()=>{
            return false;
        });
    }
    static{
        template(`
    {{#if this.tripResource.isPending}}
      Loading
    {{else if this.tripResource.isSuccess}}
      <TripAccommodationFormComponent
        @form={{{this.formAccommodationData}}}
        @accommodationPromise={{@accommodationPromise}}
        @onSubmit={{this.onSubmit}}
      />
      <div class="card mt-5">
        <div class="border-b border-light-dark py-4 px-6 flex items-center justify-between">
          <h5 class="card-title">Extras</h5>
          <ButtonText type="submit" @kind="soft-primary" {{on "click" this.onAddExtra}}>
            <i class="ti ti-plus text-base"></i>
            Nuevo
          </ButtonText>
        </div>
        <div class="card-body">
          {{#if this.extras.length}}
            <ExtrasFormComponent
              @extras={{this.extras}}
              @onNewExtra={{this.onNewExtra}}
              @onUpdateExtra={{this.onUpdateExtra}}
              @onRemoveExtra={{this.onRemoveExtra}}
            />
          {{else}}
            <span>Este alojamiento no incluye extras</span>
          {{/if}}
        </div>
      </div>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
