import EmberRouter from '@ember/routing/router';

import { properLinks } from 'ember-primitives/proper-links';
import config from 'vfc-admin/config/environment';

@properLinks
export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('login');
  this.route('request-password');
  this.route('change-password');
  this.route('authenticated', { path: '' }, function () {
    this.route('home', { path: '/' });
    this.route('clients', function () {
      this.route('index', { path: '/' });
      this.route('edit', { path: '/:id' });
      this.route('create');
    });

    this.route('configs', function () {
      this.route('payment');
    });
    this.route('calendar');
    this.route('trips', function () {
      this.route('current-course', function () {
        this.route('index', { path: '/' });
        this.route('trip', { path: '/:id' }, function () {
          this.route('index', { path: '/' });
          this.route('payments');
          this.route('invoices');
          this.route('passengers');
          this.route('rooms');
          this.route('documents');
          this.route('accommodation');
          this.route('transport');
        });
      });
      this.route('pending', function () {
        this.route('index', { path: '/' });
        this.route('trip', { path: '/:id' }, function () {
          this.route('index', { path: '/' });
          this.route('payments');
          this.route('invoices');
          this.route('passengers');
          this.route('rooms');
          this.route('documents');
          this.route('accommodation');
          this.route('transport');
        });
      });
      this.route('incomplete', function () {
        this.route('index', { path: '/' });
        this.route('edit', { path: '/:id' });
      });
    });
    this.route('schools', function () {
      this.route('index', { path: '/' });
      this.route('edit', { path: '/:id' });
      this.route('create');
    });
    this.route('faqs', function () {
      this.route('index', { path: '/' });
      this.route('edit', { path: '/:id' });
      this.route('create');
    });
    this.route('profile');
    this.route('not-found', { path: '/*path' });
  });
});
