import Service from '@ember/service';

import { TrackedArray } from 'tracked-built-ins';

export type Notification = {
  id: string;
  message: string;
  type: 'success' | 'error' | 'info' | 'warning';
  duration: number;
};

export default class ToastService extends Service {
  notifications: Notification[] = new TrackedArray<Notification>([]);

  add(notification: Notification) {
    this.notifications.push(notification);

    if (notification.duration) {
      setTimeout(() => {
        this.remove(notification.id);
      }, notification.duration);
    }
  }

  remove(id: string) {
    this.notifications.splice(
      this.notifications.findIndex((notification) => notification.id === id),
      1
    );
  }

  removeAll() {
    this.notifications.clear();
  }
}
