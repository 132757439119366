import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import TripAccommodationTabComponent from 'vfc-admin/components/pages/trip-accommodation-tab';
import type { Trip } from 'vfc-admin/types/trip';
import type { Accommodation } from 'vfc-admin/models/Accommodation';
export default RouteTemplate<{
    Args: {
        model: {
            tripPromise: Promise<Trip>;
            accommodationPromise: Promise<Accommodation>;
        };
    };
}>(template(`
    <TripAccommodationTabComponent
      @tripPromise={{@model.tripPromise}}
      @accommodationPromise={{@model.accommodationPromise}}
    />
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
