import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type ClientService from 'vfc-admin/services/client-service';
import type RelationService from 'vfc-admin/services/relation-service';
import type SchoolService from 'vfc-admin/services/school-service';

export default class ClientEditRoute extends Route {
  @service declare clientService: ClientService;
  @service declare schoolService: SchoolService;
  @service declare relationService: RelationService;

  async model(params: { id: string }) {
    // TODO Manage 404 error
    const clientPromise = this.clientService.get(params.id);

    const schoolsPromise = this.schoolService.findAll('', { page: 1, pageSize: 0 });
    const clientRelationsPromise = this.relationService.findAll();

    return { clientPromise, clientRelationsPromise, schoolsPromise };
  }
}
