import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { type FormData } from 'ember-headless-form';
import RouteTemplate from 'ember-route-template';
import ClientFormComponent from 'vfc-admin/components/forms/clientForm';
import { awaitPromise } from 'vfc-admin/helpers/await-promise';
import type RouterService from '@ember/routing/router-service';
import type { Client, ClientForm } from 'vfc-admin/models/Client';
import type ClientService from 'vfc-admin/services/client-service';
import { on } from '@ember/modifier';
import type NotificationService from 'vfc-admin/services/notification-service';
import { Notification } from 'vfc-admin/services/notification-service';
import { NotificationModal } from 'vfc-admin/components/ui/notification-modal';
import { tracked } from '@glimmer/tracking';
import PhWarningCircle from 'ember-phosphor-icons/components/ph-warning-circle';
interface ClientEditSignature {
    Element: HTMLDivElement;
    Args: {
        clientPromise: Promise<Client>;
    };
}
let ClientEditComponent = class ClientEditComponent extends Component<ClientEditSignature> {
    @service
    clientService: ClientService;
    @service
    router: RouterService;
    @service
    notificationService: NotificationService;
    clientForm: ClientForm;
    @tracked
    showResetLink: boolean = false;
    userResetLink: string = '';
    get clientResource() {
        return awaitPromise<ClientForm>(this.args.clientPromise.then((client1)=>{
            this.clientForm = client1 as ClientForm;
            return this.clientForm;
        }));
    }
    @action
    hideResetLink() {
        this.showResetLink = false;
    }
    @action
    copyLinkAndClose() {
        this.hideResetLink();
        navigator.clipboard.writeText(this.userResetLink);
    }
    @action
    async generateUserLink(event1: Event) {
        const eventTarget1 = event1.target as HTMLInputElement;
        eventTarget1.disabled = true;
        this.clientService.generateUserLink(String(this.clientForm.id)).then(({ resetLink: resetLink1 })=>{
            this.userResetLink = resetLink1;
            this.showResetLink = true;
        }).catch((error1: Error)=>{
            console.error(error1);
        }).finally(()=>{
            eventTarget1.disabled = false;
        });
    }
    @action
    async notifyClient() {
        await this.clientService.notify(this.clientForm.id).then(()=>{
            this.notificationService.notify(new Notification({
                type: 'success',
                message: 'Mensaje enviado correctamente'
            }));
        }).catch(()=>{
            this.notificationService.notify(new Notification({
                type: 'error',
                message: 'Error al enviar el mensaje'
            }));
        });
    }
    @action
    async onSubmit(data1: FormData<ClientForm>) {
        await this.clientService.update(this.clientForm.id, data1);
        this.router.transitionTo('authenticated.clients');
    }
    static{
        template(`
    {{#if this.clientResource.isPending}}
      Loading
    {{else if this.clientResource.isSuccess}}
      <div class="card">
        <div class="col-span-12">
          <div class="border-b border-light-dark py-4 px-6 flex items-center justify-between">
            <h5 class="card-title mb-0">Client: {{this.clientForm.name}}</h5>
            <div class="flex gap-2">
              <button
                {{on "click" this.notifyClient}}
                type="button"
                class="btn-md inline-flex items-center gap-x-2 text-sm font-semibold rounded-md border border-transparent bg-lightprimary text-primaryemphasis enabled:hover:bg-primary enabled:hover:text-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 disabled:opacity-50 disabled:bg-lightprimary"
              >
                <i class="ti ti-mail text-base"></i>
                Notificar usuario
              </button>
              <button
                {{on "click" this.generateUserLink}}
                type="button"
                class="group/contract btn-md inline-flex items-center gap-x-2 text-sm font-semibold rounded-md border border-transparent bg-lightsecondary text-secondaryemphasis enabled:hover:bg-secondary enabled:hover:text-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 disabled:opacity-50 disabled:bg-lightsecondary"
              >
                <i class="ti ti-file-text text-base group-disabled/contract:hidden"></i>
                <span
                  class="animate-spin inline-block size-4 border-[3px] border-current border-t-transparent text-secondaryemphasis enabled:group-hover/contract:text-white rounded-full group-enabled/contract:hidden"
                  role="status"
                  aria-label="loading"
                ></span>
                Generar enlace usuario
              </button>

            </div>
          </div>
        </div>

        <div class="card-body">
          <ClientFormComponent @onSubmit={{this.onSubmit}} @client={{this.clientForm}} />
        </div>

        {{#if this.showResetLink}}
          <NotificationModal
            @title="Link de usuario"
            @message={{this.userResetLink}}
            @onConfirm={{this.copyLinkAndClose}}
            @confirmButtonText="Copiar enlace"
            @onClose={{this.hideResetLink}}
          >
            <:icon>
              <PhWarningCircle class="fill-blue-400 w-10 h-10" />
            </:icon>
          </NotificationModal>
        {{/if}}
      </div>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate<{
    Args: {
        model: {
            clientPromise: Promise<Client>;
        };
    };
}>(template(`<ClientEditComponent @clientPromise={{@model.clientPromise}} />`, {
    eval () {
        return eval(arguments[0]);
    }
}));
