import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { on } from '@ember/modifier';
import { action, get } from '@ember/object';
import { service } from '@ember/service';
import { fn } from '@ember/helper';
import ButtonText from 'vfc-admin/components/ui/button/text';
import { TrackedObject } from 'tracked-built-ins';
import { type FormData, HeadlessForm } from 'ember-headless-form';
import { validateInputNatively } from 'vfc-admin/utils/form-utils';
import type { Extra } from 'vfc-admin/types/trip';
export interface ExtrasFormSignature {
    Element: HTMLElement;
    Args: {
        extras?: Extra[];
        onResetForm?: (event: Event) => void;
        onUpdateExtra: (data: FormData<Extra>) => Promise<Extra>;
        onRemoveExtra: (extra: Extra) => Promise<boolean>;
        onNewExtra?: (extra: Extra, index: number) => Promise<Extra>;
    };
}
export default class ExtrasFormComponent extends Component<ExtrasFormSignature> {
    validateInputNatively = (event1: Event)=>validateInputNatively.call(this, event1);
    @tracked
    errors = [];
    loading = new TrackedObject<Record<number, boolean>>({});
    @action
    onSubmit(index1: number, extra1: FormData<Extra>) {
        if (extra1.id) {
            this.onUpdateExtra(extra1, index1);
        } else {
            this.onNewExtra(extra1, index1);
        }
    }
    @action
    onUpdateExtra(extra1: Extra, index1: number) {
        this.loading[index1] = true;
        this.args?.onUpdateExtra?.(extra1).finally(()=>{
            this.loading[index1] = false;
        });
    }
    @action
    onRemoveExtra(extra1: Extra, index1: number) {
        this.loading[index1] = true;
        this.args?.onRemoveExtra?.(extra1).finally(()=>{
            this.loading[index1] = false;
        });
    }
    @action
    onNewExtra(extra1: Extra, index1: number) {
        this.loading[index1] = true;
        this.args?.onNewExtra?.(extra1, index1).finally(()=>{
            this.loading[index1] = false;
        });
    }
    @action
    isLoading(index1: number) {
        return this.loading[index1];
    }
    static{
        template(`
    {{#each this.args.extras as |extra index|}}
      <fieldset class="my-2 border p-3 rounded">
        <HeadlessForm
          @data={{extra}}
          @validateOn="focusout"
          @revalidateOn="input"
          @onSubmit={{fn this.onSubmit index}}
          as |form|
        >
          <div class="grid grid-cols-12 md:gap-6 gap-y-3">
            <div class="md:col-span-6 col-span-12">
              <form.Field @name="name" as |field|>
                <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
                  Nombre
                  <span class="text-red-500">*</span>
                </field.Label>
                <field.Input
                  required
                  maxLength="255"
                  class="form-control py-2 {{if field.isInvalid 'border-error'}}"
                  {{on "invalid" this.validateInputNatively}}
                />
                <field.Errors class="py-1 text-xs text-red-300" />
              </form.Field>
            </div>
            <div class="md:col-span-6 col-span-12">
              <form.Field @name="amount" as |field|>
                <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
                  Importe
                  <span class="text-red-500">*</span>
                </field.Label>
                <field.Input
                  required
                  type="number"
                  min="0"
                  step="0.01"
                  {{on "invalid" this.validateInputNatively}}
                  class="form-control py-2 {{if field.isInvalid 'border-error'}}"
                />
                <field.Errors class="py-1 text-xs text-red-300" />
              </form.Field>
            </div>
            <div class="col-span-12">
              <form.Field @name="observations" as |field|>
                <field.Label class="text-dark dark:text-darklink font-semibold block">
                  Observaciones Internas
                </field.Label>
                <field.Textarea
                  rows="1"
                  class="py-2.5 px-4 block w-full form-control"
                  {{on "invalid" this.validateInputNatively}}
                />
                <field.Errors class="text-xs text-red-500" />
              </form.Field>
            </div>
          </div>
          <div class="flex gap-3 justify-end mt-6">
            <ButtonText
              type="submit"
              @kind="solid-primary"
              @size="medium"
              @isLoading={{this.isLoading index}}
              disabled={{this.isLoading index}}
            >
              {{#if extra.id}}Guardar{{else}}Añadir{{/if}}
            </ButtonText>
            {{#if extra.id}}
              <ButtonText
                type="button"
                @kind="soft-error"
                @size="medium"
                disabled={{this.isLoading index}}
                {{on "click" (fn this.onRemoveExtra extra)}}
              >
                Borrar
              </ButtonText>
            {{/if}}
          </div>
        </HeadlessForm>
      </fieldset>
    {{/each}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
