import { template } from "@ember/template-compiler";
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import PhWarningCircle from 'ember-phosphor-icons/components/ph-warning-circle';
import { Modal } from 'ember-primitives/components/dialog';
import ButtonText from 'vfc-admin/components/ui/button/text';
import { noop } from 'vfc-admin/utils/fn-utils';
import type { TOC } from '@ember/component/template-only';
type ModalState = {
    /**
   * Represents the open state of the `<dialog>` element.
   */ isOpen: boolean;
    /**
   * Closes the `<dialog>` element
   * Will throw an error if `Dialog` is not rendered.
   */ close: () => void;
    /**
   * Opens the `<dialog>` element.
   * Will throw an error if `Dialog` is not rendered.
   */ open: () => void;
    /**
   * This is the `<dialog>` element (with some defaults pre-wired).
   * This is required to be rendered.
   */ Dialog: unknown;
};
const errorMessageDiv = document.querySelector<HTMLDivElement>('#error-message');
const confirmAndClose = async (onConfirm1: () => Promise<boolean>, modal1: ModalState, event1: Event)=>{
    const isOK1 = await onConfirm1.call(this);
    if (isOK1) {
        modal1.close?.();
        event1.preventDefault();
    } else {
        if (!errorMessageDiv) return;
        errorMessageDiv.textContent = 'Error no esperado. Por favor, intentelo mas tarde.';
    }
};
const NotificationModal: TOC<{
    Element: HTMLElement;
    Args: {
        title: string;
        message: string;
        confirmButtonText?: string;
        cancelButtonText?: string;
        onConfirm: () => Promise<boolean>;
        onClose: (m: ModalState) => void;
    };
    Blocks: {
        icon?: [];
    };
}> = template(`
  <div class="fixed z-20 inset-0 flex items-center justify-center">
    <Modal @open={{true}} as |m|>
      {{#if m.isOpen}}
        <div class="fixed inset-0 bg-black opacity-50"></div>
      {{/if}}
      <m.Dialog class="bg-white border border-neutral-200 rounded-lg w-[352px]">
        <form method="dialog">
          <main class="px-6 pt-4 pb-8 flex flex-col items-center">
            <header class="w-full flex justify-end">
              <button type="button" {{on "click" (if @onClose (fn @onClose m) noop)}}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 6L6 18M6 6L18 18"
                    stroke="#0A1126"
                    stroke-width="1.75"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </header>
            {{! warning }}
            <div class="flex items-center justify-center">
              {{#if (has-block "icon")}}
                {{yield to="icon"}}
              {{else}}
                <PhWarningCircle class="fill-red-500 w-10 h-10" />
              {{/if}}
            </div>
            <div class="font-semibold text-lg pt-4 pb-2 text-center">
              {{@title}}
            </div>
            <div class="pb-2 text-center break-all">
              {{@message}}
            </div>
          </main>
          <hr class="h-px bg-neutral-400" />
          <footer class="flex justify-center gap-2 py-4 px-4">
            <ButtonText
              type="button"
              @kind="primary-error"
              @size="small"
              class="w-full"
              {{on "click" (fn confirmAndClose @onConfirm m)}}
            >
              {{#if @confirmButtonText}}
                {{@confirmButtonText}}
              {{else}}
                Sí, continuar
              {{/if}}
            </ButtonText>
            <ButtonText
              type="submit"
              @kind="outline"
              @size="small"
              class="w-full"
              {{on "click" (if @onClose (fn @onClose m) noop)}}
            >
              {{#if @cancelButtonText}}
                {{@cancelButtonText}}
              {{else}}
                Cancelar
              {{/if}}
            </ButtonText>
          </footer>
          <div id="error-message" class="text-red-500"></div>
        </form>
      </m.Dialog>
    </Modal>

  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export { NotificationModal };
