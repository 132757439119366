import Service from '@ember/service';
import { service } from '@ember/service';

import config from 'vfc-admin/config/environment';

import type { IncompleteTrip } from 'vfc-admin/models/schemas/IncompleteTrip';
import type { School } from 'vfc-admin/models/School';
import type AuthenticatedHttpClient from 'vfc-admin/services/auth/http-client';
import type { Paginated } from 'vfc-admin/types/paginated';
import type { Pagination } from 'vfc-admin/types/pagination';
import type { Trip } from 'vfc-admin/types/trip';
import type { TODO } from 'vfc-admin/utility-types';

export type TripsResponse = {
  data: Array<Trip>;
  meta: {
    total: number;
    page: number;
    pageSize: number;
  };
};

export default class TripService extends Service {
  @service('auth/http-client') declare authenticatedHttpClient: AuthenticatedHttpClient;

  static ENDPOINTS = {
    incomplete: '/trip/:id/incomplete',
    findAll: '/trip',
    get: '/trip/:id',
    update: '/trip/:id',
  };

  currentCourse(
    query: string,
    pagination: Pagination = { page: 1, pageSize: 10 }
  ): Promise<TripsResponse> {
    const filters = new URLSearchParams({
      state: 'inprogress,closed',
      page: String(pagination.page),
      pageSize: String(pagination.pageSize),
    });

    if (query) {
      filters.append('query', query);
    }

    return this.authenticatedHttpClient.GET<TODO>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${TripService.ENDPOINTS.findAll}?${filters.toString()}`,
    });
  }

  update(id: string, body: TODO): Promise<Trip> {
    const endpoint = TripService.ENDPOINTS.update.replace(':id', id);
    const headers = new Headers();

    headers.append('Content-Type', 'application/json');

    return this.authenticatedHttpClient.PUT<TODO>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}`,
      headers,
      body,
    });
  }

  get(id: string): Promise<TODO> {
    const endpoint = TripService.ENDPOINTS.get.replace(':id', id);

    return this.authenticatedHttpClient.GET<TODO>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}`,
    });
  }

  findAll(
    query?: string,
    pagination: Pagination = { page: 1, pageSize: 10 }
  ): Promise<Paginated<School>> {
    const filters = new URLSearchParams({
      page: String(pagination.page),
      pageSize: String(pagination.pageSize),
    });

    if (query) {
      filters.append('query', query);
    }

    return this.authenticatedHttpClient.GET<TODO>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${TripService.ENDPOINTS.findAll}?${filters.toString()}`,
    });
  }

  incomplete(
    query: string,
    pagination: Pagination = { page: 1, pageSize: 10 }
  ): Promise<TripsResponse> {
    const filters = new URLSearchParams({
      state: 'incomplete',
      page: String(pagination.page),
      pageSize: String(pagination.pageSize),
    });

    if (query) {
      filters.append('query', query);
    }

    return this.authenticatedHttpClient.GET<TODO>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${TripService.ENDPOINTS.findAll}?${filters.toString()}`,
    });
  }

  pending(query: string, pagination: Pagination = { page: 1, pageSize: 10 }): Promise<TODO> {
    const filters = new URLSearchParams({
      state: 'pending',
      page: String(pagination.page),
      pageSize: String(pagination.pageSize),
    });

    if (query) {
      filters.append('query', query);
    }

    return this.authenticatedHttpClient.GET<TODO>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${TripService.ENDPOINTS.findAll}?${filters.toString()}`,
    });
  }

  postIncomplete(id: string, body: IncompleteTrip): Promise<TODO> {
    const endpoint = TripService.ENDPOINTS.incomplete.replace(':id', id);
    const headers = new Headers();

    headers.append('Content-Type', 'application/json');

    return this.authenticatedHttpClient.POST<TODO>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}`,
      headers,
      body,
    });
  }

  generateProforma(id: string): Promise<Response> {
    const endpoint = TripService.ENDPOINTS.get.replace(':id', id);

    return this.authenticatedHttpClient.GET<Response>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}/proforma`,
    });
  }

  generateContract(id: string): Promise<Response> {
    const endpoint = TripService.ENDPOINTS.get.replace(':id', id);

    return this.authenticatedHttpClient.GET<Response>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}/contract`,
    });
  }

  downloadPassengersList(id: string): Promise<Response> {
    const endpoint = TripService.ENDPOINTS.get.replace(':id', id);

    return this.authenticatedHttpClient.GET<Response>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}/passengers/download`,
    });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'user-service': AuthenticatedHttpClient;
  }
}
