import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import TripTransportTabComponent from 'vfc-admin/components/pages/trip-transport-tab';
import type { Trip } from 'vfc-admin/types/trip';
export default RouteTemplate<{
    Args: {
        model: {
            tripPromise: Promise<Trip>;
        };
    };
}>(template(`<TripTransportTabComponent @tripPromise={{@model.tripPromise}} />`, {
    eval () {
        return eval(arguments[0]);
    }
}));
